import * as yup from 'yup';

export const addValidationSchema = yup.object().shape({
  certifyingBodyPartyId: yup
    .string()
    .required('Certifying Body Party is required'),
  certificateHolderPartyId: yup
    .string()
    .required('Certificate Holder Party is required'),
  certificateType: yup
    .string()
    .required('Certificate Type is required'),
  status: yup
    .string()
    .required('Status is required'),
  certificateName: yup
    .string()
    .required('Certificate Name is required')
    .matches(/(\S.*)/, 'Certificate Name should not be blank'),
  boundingBox: yup
    .array()
    .of(
      yup.object().shape({
        lat: yup
          .number()
          .typeError('Lat should be a number')
          .min(-90, 'Lat value should be greater than -90')
          .max(90, 'Lat value should be less than 90')
          .when(['long'], {
            is: (a) => a !== undefined,
            then: yup.number()
              .typeError('Lat should be a number')
              .required('Lat is required when Long is filled'),
            otherwise: yup.number()
              .typeError('Lat should be a number')
          }),
        long: yup
          .number()
          .typeError('Long should be a number')
          .min(-180, 'Long value should be greater than -180')
          .max(180, 'Long value should be less than 180')
          .when(['lat'], {
            is: (a) => a !== undefined,
            then: yup.number()
              .typeError('Long should be a number')
              .required('Long is required when Lat is filled'),
            otherwise: yup.number()
              .typeError('Long should be a number')
          }),
      }, ['lat', 'long'])
    ),
});

export const editValidationSchema = yup.object().shape({
  certificateType: yup
    .string()
    .required('Certificate Type is required'),
  status: yup
    .string()
    .required('Status is required'),
  certificateName: yup
    .string()
    .required('Certificate Name is required')
    .matches(/(\S.*)/, 'Certificate Name should not be blank'),
    boundingBox: yup
    .array()
    .of(
      yup.object().shape({
        lat: yup
          .number()
          .typeError('Lat should be a number')
          .min(-90, 'Lat value should be greater than -90')
          .max(90, 'Lat value should be less than 90')
          .when(['long'], {
            is: (a) => a !== undefined,
            then: yup.number()
              .typeError('Lat should be a number')
              .required('Lat is required when Long is filled'),
            otherwise: yup.number()
              .typeError('Lat should be a number')
          }),
        long: yup
          .number()
          .typeError('Long should be a number')
          .min(-180, 'Long value should be greater than -180')
          .max(180, 'Long value should be less than 180')
          .when(['lat'], {
            is: (a) => a !== undefined,
            then: yup.number()
              .typeError('Long should be a number')
              .required('Long is required when Lat is filled'),
            otherwise: yup.number()
              .typeError('Long should be a number')
          }),
      }, ['lat', 'long'])
    ),
});

export const masterCertificateTypes = [
  {
    key: 0,
    value: 'EO100',
    text: 'EO100'
  },{
    key: 1,
    value: 'INTERTEK_CARBONZERO',
    text: 'INTERTEK_CARBONZERO'
  },{
    key: 2,
    value: 'INTERTEK_CARBONCLEAR',
    text: 'INTERTEK_CARBONCLEAR'
  }
];


export const masterCertificateStatus = [
  {
    key: 0,
    value: 'ACTIVE',
    text: 'ACTIVE'
  },{
    key: 1,
    value: 'INACTIVE',
    text: 'INACTIVE'
  },{
    key: 2,
    value: 'PENDING',
    text: 'PENDING'
  }
];