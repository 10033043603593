import React from 'react';
import Logo from '../../images/fiutur_logo.png';
import LoginButton from '../../components/LoginBtn/loginBtn';

const Login = () => {
  // const [loading] = useState();

  return (
    <>
      {/* {loading ? (
        <div className='loading-page'>
          <img src={LogoGif} alt='logo'></img>
        </div>
      ) : ( */}
      <div className='login'>
        <img src={Logo} alt='Logo' className='logo' />
        <section>
          <div className='btn-wrapper'>
            <LoginButton />
          </div>
        </section>
      </div>
      {/* )} */}
    </>
  );
};

export default Login;
