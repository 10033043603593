import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';

export const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) 
            func();
        else 
            didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);    
}

export const dateTimeFormatter = (value) => {
    if(value)
        return dayjs(value).format('MM-DD-YYYY, HH:mm:ss')+ ' (GMT)';
    else 
        return '-'
}

export const dateFormatter = (value) => {
    if(value)
        return dayjs(value).format('MM-DD-YYYY');
    else 
        return '-'
}

export const dateTimeFormatterReadable = (value) => {
    return dayjs(value).format('MMM DD, YYYY, hh:mm:ss A');
}

export const readableStepName = (value) => {

    switch (value) {
        case 'DataCustodyReceivedStep':
        case 'DataCustodyReceivedEvent':
            return 'Data Custody Received';
        case 'ContextualizationStep':
        case 'ContextualizationEvent':
            return 'Contextualization';
        case 'ContextualizationRequestStep':
        case 'ContextualizationRequestEvent':
            return 'Contextualization Request';
        case 'DataCustodyRequestedStep':
        case 'DataCustodyRequestedEvent':
            return 'Data Custody Requested';
        case 'IDGeneratorStep':
        case 'IDGeneratorEvent':
            return 'Id Generator';
        case 'RegistrationStep':
        case 'RegistrationEvent':
            return 'Registration';
        case 'AssetRegistrationStep':
        case 'AssetRegistrationEvent':
            return 'Asset Registration';
        default: {
            return 'Unknown'
        }
        }
}

export const escapeEmptyDetail = (value) => {
    if (value === null || value === undefined || value === '')
        return '-';
    else
        return value;
}

export const encodedSearchTerm = (value) => {
    return encodeURIComponent(value);
}

export const escapeCommasForCsv = (data) => {
    return data ? `"${data}"` : '';
  }