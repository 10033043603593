import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { TextField, Grid, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';

import ErrorMessage from '../../../components/Error/Error';
import { useUserDispatch, tabActive } from '../../../context/UserContext'
import { listProductionEntity, addProductionEntity, editProductionEntity, listLedgerParties } from '../../../api/api';
import { productionEntityTypes } from '../../../constants/productionEntities';
import { addValidationSchema, editValidationSchema } from '../../../constants/productionEntities';

const AddProductionEntity = (props) => {
  const userDispatch = useUserDispatch();

  const [ledgerParties, setLedgerParties] = useState();
  const [currentParty, setCurrentParty] = useState();
  const [loading, setLoading] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState();

  useEffect(() => {
    if (window.location.pathname === `/production-entities`) {
      tabActive(userDispatch, 2);
    }
    window.localStorage.setItem('main_tab', 'certificates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoading(true);

    var current_party = JSON.parse(localStorage.getItem('current_party'));
    setCurrentParty(current_party);

    listLedgerParties()
      .then((res) => {
        buildLedgerPartiesList(res.data.content);
        const parties = res.data.content;

        if (props.productionEntityId !== undefined) {
          //get data for edit
          listProductionEntity(props.productionEntityId)
            .then((res) => {
              setIsEdit(true);
        
              setFormData({
                certifyingBodyPartyId: res.data.certifyingBodyPartyId,
                certifyingBodyPartyName: res.data.certifyingBodyPartyName,
                certificateHolderPartyId: res.data.certificateHolderPartyId,
                certificateHolderPartyName: res.data.certificateHolderPartyName,
                name: res.data.name,
                externalId: res.data.externalId,
                productionEntityType: res.data.productionEntityType,
                supplementalData: res.data.supplementalData,
                location: res.data.location,
                lat: res.data.latlong ? res.data.latlong.split(',')[0] : '',
                long: res.data.latlong ? res.data.latlong.split(',')[1] : '',
                volumeUnit: res.data.volumeUnit,
                volume: res.data.volume ? res.data.volume : ''
              });
            })
            .catch((err) => {
              setHasError(true);
              setErrorStatus(err.response.status);
              setErrorDetail(err.response.data.detail);
            })
            .finally(() => {
              setLoading(false);
            });
            
        } else {
          setFormData({
            certifyingBodyPartyId: current_party.id,
            certifyingBodyPartyName: parties.find(el => el.party === current_party.id).partyName,
            certificateHolderPartyId: '',
            certificateHolderPartyName: '',
            name: '',
            externalId: '',
            productionEntityType: '',
            supplementalData: [{name:'', value:''}],
            location: '',
            lat: '',
            long: '',
            volumeUnit: '',
            volume: ''
          });
          setLoading(false);
        }

      }).catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buildLedgerPartiesList = (prods) => {
    const ledgerParties = prods.map((el, index) => {
      return { key: index, text: el.partyName, value: el.party};
    })
    setLedgerParties(ledgerParties);
  }

  const handleSubmit = (values, formikHelpers) => {

    values.supplementalData.forEach((el, index) => {
      if(el.name === '' && el.value === '')
        values.supplementalData.splice(index, 1);
    })

    if(values.lat!=='' && values.long!=='')
      values = {
        ...values,
        latlong: values.lat+', '+values.long,
        volumeUnit: values.volumeUnit,
        volume: values.volume
      };
    else
      values = {
        ...values,
        latlong: null,
        volumeUnit: values.volumeUnit,
        volume: values.volume
      };

    if(isEdit) {
      delete values.certifyingBodyPartyId;
      delete values.certifyingBodyPartyName;
      delete values.certificateHolderPartyId;
      delete values.certificateHolderPartyName;
    }

    delete values.lat;
    delete values.long;

    if(isEdit) {
      editProductionEntity(props.productionEntityId, values)
      .then((res) => {
        props.onCloseSuccessfully();
      })
      .catch((err) => {
        if (err.response.data['error.key'] !== null) {
          formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
          formikHelpers.setSubmitting(false);
        } else if(err.response.data.fieldErrors !== null) {
          let errors = [];
          err.response.data.fieldErrors.forEach(el => {
            errorStatus.push({[el.field]: el.message})
          })
          formikHelpers.setErrors(errors);
          formikHelpers.setSubmitting(false);
        } else {
          setHasError(true)
          setErrorStatus(err.response.status)
          setErrorDetail(err.response.data.detail);
        }
      })
    } else {
      addProductionEntity(values)
      .then((res) => {
        props.onCloseSuccessfully();
      })
      .catch((err) => {
        if (err.response.data['error.key'] !== null) {
          formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
          formikHelpers.setSubmitting(false);
        } else if(err.response.data.fieldErrors !== null) {
          let errors = {};
          err.response.data.fieldErrors.forEach(el => {
            errors[el.field] = el.message;
          })
          formikHelpers.setErrors(errors);
          formikHelpers.setSubmitting(false);
        } else {
          setHasError(true)
          setErrorStatus(err.response.status)
          setErrorDetail(err.response.data.detail);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }

  return (
    <>
      {loading || !formData || !ledgerParties ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          { hasError ? (
            <Modal
              open={props.open}
              onClose={props.onClose}
              className='formModal'
            >
              <Modal.Header>{props.productionEntityId ? 'Edit' : 'Add'} Production Entity</Modal.Header>
              <Modal.Content className='formModal-content'>
                <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
              </Modal.Content>
              <Modal.Actions>
                  <Button onClick={props.onClose}>
                    Close
                  </Button>
                </Modal.Actions>
            </Modal>
          ) : (
            <Formik
              initialValues={formData}
              validationSchema={isEdit? editValidationSchema : addValidationSchema}
              onSubmit={handleSubmit}
            >
                { formProps => ( 
                  <form onSubmit={formProps.handleSubmit} noValidate >
                    <Modal
                      open={props.open}
                      onClose={props.onClose}
                      className='formModal'
                    >
                      <Modal.Header>{props.productionEntityId ? 'Edit' : 'Add'} Production Entity</Modal.Header>
                      <Modal.Content className='formModal-content' >
                        <Grid container spacing={2}>
                          { isEdit ?
                            <>
                              <Grid item md={12}>
                                <TextField 
                                  fullWidth={true}
                                  disabled
                                  label='Certifying Body Party'
                                  id='certifyingBodyPartyName'
                                  name='certifyingBodyPartyName'
                                  placeholder='Certifying Body Party'
                                  value={formProps.values.certifyingBodyPartyName ?? ''}
                                />
                              </Grid>
                              <Grid item md={12}>
                                <TextField 
                                  fullWidth={true}
                                  disabled
                                  label='Certificate Holder Party'
                                  id='certificateHolderPartyName'
                                  name='certificateHolderPartyName'
                                  placeholder='Certificate Holder Party'
                                  value={formProps.values.certificateHolderPartyName ?? ''}
                                />
                              </Grid>
                            </>
                          :
                            <>
                              { currentParty && currentParty.roles.includes('RegistryOperator') &&
                                <Grid item md={12}>
                                  <Autocomplete
                                    id='certifyingBodyPartyId'
                                    fullWidth={true}
                                    disableClearable
                                    options={ledgerParties}
                                    renderInput={(params) => (
                                      <TextField
                                        required
                                        {...params}
                                        label='Certifying Body Party'
                                        error={formProps.touched.certifyingBodyPartyId && Boolean(formProps.errors.certifyingBodyPartyId)}
                                        helperText={formProps.touched.certifyingBodyPartyId && formProps.errors.certifyingBodyPartyId}
                                      />
                                    )}
                                    getOptionLabel={(option) => option.text}
                                    value={ledgerParties.find(el => el.value === formProps.values.certifyingBodyPartyId)}
                                    onChange={
                                      (_event, newLedgerParty) => {
                                        formProps.handleChange({target: {value: newLedgerParty.value, name: 'certifyingBodyPartyId'}});
                                        formProps.handleChange({target: {value: newLedgerParty.text, name: 'certifyingBodyPartyName'}});
                                      }
                                    }
                                  />
                                </Grid>
                              }
                              <Grid item md={12}>
                                <Autocomplete
                                  id='certificateHolderPartyId'
                                  fullWidth={true}
                                  disableClearable
                                  options={ledgerParties}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Certificate Holder Party'
                                      error={formProps.touched.certificateHolderPartyId && Boolean(formProps.errors.certificateHolderPartyId)}
                                      helperText={formProps.touched.certificateHolderPartyId && formProps.errors.certificateHolderPartyId}
                                    />
                                  )}
                                  getOptionLabel={(option) => option.text}
                                  value={ledgerParties.find(el => el.value === formProps.values.certificateHolderPartyId)}
                                  onChange={
                                    (_event, newLedgerParty) => {
                                      formProps.handleChange({target: {value: newLedgerParty.value, name: 'certificateHolderPartyId'}});
                                      formProps.handleChange({target: {value: newLedgerParty.text, name: 'certificateHolderPartyName'}});
                                    }
                                  }
                                />
                              </Grid>
                            </>
                          }
                          <Grid item md={12}>
                            <TextField
                              required
                              fullWidth={true}
                              label='Production Entity Name'
                              id='name'
                              name='name'
                              placeholder='Production Entity Name'
                              value={formProps.values.name}
                              onChange={formProps.handleChange}
                              error={formProps.touched.name && Boolean(formProps.errors.name)}
                              helperText={formProps.touched.name && formProps.errors.name}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              required
                              fullWidth={true}
                              label='External Id'
                              id='externalId'
                              name='externalId'
                              placeholder='External Id'
                              value={formProps.values.externalId}
                              onChange={formProps.handleChange}
                              error={formProps.touched.externalId && Boolean(formProps.errors.externalId)}
                              helperText={formProps.touched.externalId && formProps.errors.externalId}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              fullWidth={true}
                              select
                              required
                              label='Production Entity Type'
                              name='productionEntityType'
                              placeholder='Production Entity Type'
                              value={formProps.values.productionEntityType ?? ''}
                              onChange={formProps.handleChange}
                              error={formProps.touched.productionEntityType && Boolean(formProps.errors.productionEntityType)}
                              helperText={formProps.touched.productionEntityType && formProps.errors.productionEntityType}
                              >
                                {productionEntityTypes.map(el =>
                                  <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                                )}
                            </TextField>
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              fullWidth={true}
                              label='Location'
                              id='location'
                              name='location'
                              placeholder='Location'
                              value={formProps.values.location}
                              onChange={formProps.handleChange}
                            />
                          </Grid>
                          <Grid container item md={12} spacing={2} display='contents' alignItems='flex-end'>
                            <Grid item md={6}>
                              <TextField
                                fullWidth={true}
                                type='number'
                                label='Lat'
                                id='lat'
                                name='lat'
                                placeholder='Lat'
                                value={formProps.values.lat}
                                onChange={formProps.handleChange}
                                error={formProps.touched.lat && (Boolean(formProps.errors.lat))}
                                helperText={formProps.touched.lat && formProps.errors.lat}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                fullWidth={true}
                                type='number'
                                label='Long'
                                id='long'
                                name='long'
                                placeholder='Long'
                                value={formProps.values.long}
                                onChange={formProps.handleChange}
                                error={formProps.touched.long && Boolean(formProps.errors.long)}
                                helperText={formProps.touched.long && formProps.errors.long}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item md={12} spacing={2} display='contents' alignItems='flex-end'>
                            <Grid item md={6}>
                              <TextField
                                fullWidth={true}
                                label='Volume Unit'
                                id='volumeUnit'
                                name='volumeUnit'
                                placeholder='Volume Unit'
                                value={formProps.values.volumeUnit}
                                onChange={formProps.handleChange}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                fullWidth={true}
                                type='number'
                                label='Volume'
                                id='volume'
                                name='volume'
                                placeholder='Volume'
                                value={formProps.values.volume}
                                onChange={formProps.handleChange}
                              />
                            </Grid>
                          </Grid>
                          <Grid item md={12}>
                            <p>Supplemental Data</p>
                          </Grid>
                          <FieldArray
                            label='Supplemental Data'
                            id='supplementalData'
                            name='supplementalData'
                            render={arrayHelpers => (
                              <>
                                {formProps.values.supplementalData?.map((sData, index) => (
                                  <Grid container item md={12} spacing={2} display='contents' alignItems='flex-end' key={index}>
                                    <Grid item md={5}>
                                      <TextField 
                                        fullWidth={true}
                                        label='Name'
                                        id={`supplementalData[${index}].name`}
                                        name={`supplementalData[${index}].name`} 
                                        value={sData.name || ''}
                                        error={formProps.touched.supplementalData && formProps.errors.supplementalData !== undefined && Boolean(formProps.errors.supplementalData[index]?.name)}
                                        helperText={formProps.touched.supplementalData && formProps.errors.supplementalData !== undefined && formProps.errors.supplementalData[index]?.name}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                      />
                                    </Grid>
                                    <Grid item md={5}>
                                      <TextField 
                                        fullWidth={true}
                                        label='Value'
                                        id={`supplementalData[${index}].value`}
                                        name={`supplementalData.${index}.value`}
                                        value={sData.value || ''}
                                        error={formProps.touched.supplementalData && formProps.errors.supplementalData !== undefined && Boolean(formProps.errors.supplementalData[index]?.value)}
                                        helperText={formProps.touched.supplementalData && formProps.errors.supplementalData !== undefined && formProps.errors.supplementalData[index]?.value}
                                        onChange={formProps.handleChange} 
                                        onBlur={formProps.handleBlur}
                                      />
                                    </Grid> 
                                    <Grid item md={2}>
                                      <div
                                        className='font-icon-wrapper'
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        <IconButton aria-label='delete'>
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>
                                    </Grid>
                                  </Grid>
                                ))}

                                <Grid item md={12}>
                                  <Button onClick={() => arrayHelpers.push({ name: '', value: '' })} className='actionButton'>
                                    Add more
                                  </Button>
                                </Grid>
                              </>
                            )}
                          />
                        </Grid>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button disabled={formProps.isSubmitting} onClick={props.onClose}>Cancel</Button>
                        <Button disabled={formProps.isSubmitting} className='actionButton' type='submit' onClick={formProps.handleSubmit}>{ isEdit ? 'Save' : 'Add' }</Button>
                      </Modal.Actions>
                    </Modal>
                  </form>
                )
              } 
            </Formik>
          )}
        </>  
      )}
    </>
  )
}

export default AddProductionEntity
