import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { ToggleButtonGroup, ToggleButton, Pagination } from '@mui/material/';

import StripedTable from '../../components/Table/StripedTable.jsx';
import ErrorMessage from '../../components/Error/Error';
import { listIssuedCertificates } from '../../api/api';
import { useUserDispatch, tabActive } from '../../context/UserContext';
import { dateFormatter, escapeEmptyDetail, useDidMountEffect } from '../../utils/utils.jsx';
import IssuedCertificateToPDF from '../../pdfs/IssuedCertificate';

const IssuedCertificates = () => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const [, setInitialRows] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  // table filter
  const [searchActivationDate, setSearchActivationDate] = useState(null);
  const [activationDateError, setActivationDateError] = useState('');
  const [searchExpirationDate, setSearchExpirationDate] = useState(null);
  const [expirationDateError, setExpirationDateError] = useState('');
  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    if (window.location.pathname === '/issued-certificates') {
      tabActive(userDispatch, 1);
    }

    getIssuedCertificates();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidMountEffect(() => {
    getIssuedCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchActivationDate, searchExpirationDate]);

  useDidMountEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      setLoading(true);
      getIssuedCertificates();
    }
    window.localStorage.setItem('main_tab', 'certificates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  const getIssuedCertificates = () => {

    setLoading(true);
    
    listIssuedCertificates(
      itemsPerPage,
      page - 1,
      searchActivationDate,
      searchExpirationDate
    )
      .then((result) => {
        setInitialRows(result.data.content);
        setRows(result.data.content);
        setNoOfPages(result.data.totalPages);
      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleItemsPerPage = (event, newAmount) => {
    setItemsPerPage(newAmount);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {loading || (rows?.length === undefined && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <div className='Home'>
              <section className='filter-section'>
                <div className='datepicker-wrapper'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format='MM-DD-YYYY'
                      label='Activation Date'
                      value={dayjs(dayjs(searchActivationDate).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(searchActivationDate) : null}
                      onChange={(newValue) => {
                        setTimeout(function(){
                          if (newValue === '' || newValue === null) {
                            setSearchActivationDate(null);
                            setActivationDateError(null);
                          } else if (!dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()){
                            setActivationDateError('Invalid date');
                          } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).year() < 1900){
                            setActivationDateError('Activation date should be greater than 1899');
                          } else if(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                            setSearchActivationDate(dayjs(newValue).format('YYYY-MM-DD'));
                            setActivationDateError(null);
                          }
                        }, 500);
                      }}
                      slotProps={{
                        actionBar: { actions: ["clear"], position: "left" }
                      }}
                    />
                  </LocalizationProvider>
                  <p>
                    {activationDateError}
                  </p>
                </div>
                <div className='datepicker-wrapper'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format='MM-DD-YYYY'
                      label='Expiration Date'
                      value={dayjs(dayjs(searchExpirationDate).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(searchExpirationDate) : null}
                      onChange={(newValue) => {
                        setTimeout(function(){
                          if (newValue === '' || newValue === null) {
                            setSearchExpirationDate(null);
                            setExpirationDateError(null);
                          } else if (!(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())){
                            setExpirationDateError('Invalid date');
                          } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).year() < 1900){
                            setExpirationDateError('Expiration Date should be greater than 1899');
                          } else if(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                            setSearchExpirationDate(dayjs(newValue).format('YYYY-MM-DD'));
                            setExpirationDateError(null);
                          }
                        }, 500);
                      }}
                      slotProps={{
                        actionBar: { actions: ["clear"], position: "left" }
                      }}
                    />
                  </LocalizationProvider>
                  <p>
                    {expirationDateError}
                  </p>
                </div>
              </section>
              <section className='content'>
                {rows?.length !== undefined && !loading ? (
                  <>
                    <StripedTable
                      aria-label='simple table'
                      rowsClickable
                      headings={[
                        'Id',
                        'Master Certificate Id',
                        'External Payload Id',
                        'Activation Date',
                        'Expiration Date',
                        ''
                      ]}
                      rows={rows?.map((data, index) => {
                        if (data) {
                          return {
                            elements: [
                              <p>{escapeEmptyDetail(data.issuedCertificateId)}</p>,
                              <p>{escapeEmptyDetail(data.masterCertificateId)}</p>,
                              <p>{escapeEmptyDetail(data.externalPayloadId)}</p>,
                              <p>{dateFormatter(data.activationDate)}</p>,
                              <p>{dateFormatter(data.expirationDate)}</p>,
                              <section className='export-btn-wrapper'>
                                <PDFDownloadLink
                                  document={IssuedCertificateToPDF(data)}
                                  fileName={(data.certificateName).replaceAll('.','_')}
                                >
                                  <Tooltip placement={'top'} arrow title='Export PDF'>
                                    <button className='sm-btn' onClick={(e) => e.stopPropagation()}> pdf </button>
                                  </Tooltip>
                                </PDFDownloadLink>
                              </section>
                            ],
                            onClick: () => {
                              navigate(`/issued-certificates/${data.issuedCertificateId}`);
                            },
                          };
                        } else {
                          return {
                            elements: [],
                          };
                        }
                      })}
                    ></StripedTable>
                    <Grid container spacing={2}>
                      <Grid item md={4}/>
                      <Grid item md={4}>
                        <Box
                          component='div'
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '3rem 0'
                          }}
                          >
                          <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChangePage}
                            defaultPage={0}
                            color='primary'
                            size='small'
                          />
                        </Box>
                      </Grid>
                      {rows?.length > 0 &&
                        <Grid item md={4} p={2}>
                          <Box
                            component='div'
                            sx={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'center',
                              padding: '3rem 0'
                            }}
                            className='perpage'>
                            <Tooltip
                              placement={'right'}
                              arrow
                              title={<span>View per page</span>}
                            >
                              <ToggleButtonGroup
                                value={itemsPerPage}
                                exclusive
                                onChange={handleItemsPerPage}
                                aria-label='text alignment'
                                size='small'
                              >
                                <ToggleButton value={10} aria-label='left aligned'>
                                  10
                                </ToggleButton>
                                <ToggleButton value={50} aria-label='centered'>
                                  50
                                </ToggleButton>
                                <ToggleButton value={100} aria-label='justified'>
                                  100
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Tooltip>
                          </Box>
                        </Grid>
                      }
                    </Grid>
                  </>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      <div className='empty-table'>
                        <Loader active indeterminate size='small' />
                      </div>
                    ) : (
                      <StripedTable rows={[]}></StripedTable>
                    )}
                  </>
                )}
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default IssuedCertificates;