/* eslint-disable no-unused-vars */
import React from 'react';
import Logo from '../images/fiutur_logo.png';
import Kanit from '../fonts/Kanit-Regular.ttf';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import { readableStepName, escapeEmptyDetail } from '../utils/utils';

const EventsPDF = (eventsActives, eventsInactives, payloadID) => {

  Font.register({
    family: 'Kanit',
    src: Kanit
  })

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#03241C',
      fontFamily: 'Kanit'
    },
    logo: {
      width: '100px',
      height: 'auto',
      margin: 20,
    },
    section: {
      margin: 20,
      marginBottom: 10,
      padding: 10,
      borderRadius: 6,
      fontSize: '10px',
      fontWeight: 'normal',
      backgroundColor: '#FFFFFF',
    },
    content: {
      flexDirection: 'row',
    },
    col: {
      marginBottom: 10,
      marginRight: 20,
    },
    eventTitle: {
      marginBottom: 10,
      fontSize: '14px',
      fontWeight: 'bold',
    },
    eventSubtitle: {
      paddingTop: 15,
      fontSize: '12px',
      fontWeight: 'bold',
    },
    eventTitleError: {
      color: 'red',
      marginBottom: 10,
      fontSize: '14px',
      fontWeight: 'bold',
    },
    eventTitleNotable: {
      color: '#03241C',
      marginBottom: 10,
      fontSize: '14px',
      fontWeight: 'bold',
    },
    eventTextTitle: {
      fontSize: '12px',
      color: '#03241C',
      marginTop: 10,
    },
    eventText: {
      marginTop: 5,
      fontWeight: 'light',
    },
    pdfTitle: {
      margin: 20,
      marginBottom: 10,
      color: '#FFFFFF',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  });

  const EventsToPDF = () => (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image style={styles.logo} src={Logo} alt='logo' />
        <View style={styles.pdfTitle}>
          <Text style={styles.eventText}>Payload Id {payloadID}</Text>
          {eventsActives?.length ? (
            <Text style={styles.eventText}>Active Events:</Text>
          ) : (
            <></>
          )}
        </View>
        {
          // eslint-disable-next-line array-callback-return
          eventsActives?.map((s, index) => {
            if (s['@type']) {
              switch (s['@type']) {
                case 'DataCustodyReceivedEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                          {/* {` (${s.status})`} */}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          New Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.newCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          New Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.newCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {' '}
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'ContextualizationEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.partyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.partyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Version:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processVersion)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Service Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.serviceName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Issued Certificate:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.issuedCertificate)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventSubtitle}>
                          Starting Values
                        </Text>
                        {s.startingValues?.length > 0 ?
                          <>
                            {s.startingValues?.map((s, i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                            })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventSubtitle}>Result</Text>
                        {s.result?.length > 0 ?
                          <>
                            {s.result?.map((s, i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                            })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventTextTitle}>
                          Supporting Information:
                        </Text>
                        {s.supportingInformation?.length > 0 ?
                          <>
                            {s.supportingInformation?.map((s,i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                            })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text><Text style={styles.eventText}>
                          {' '}
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'ContextualizationRequestEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Requesting Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.requestingPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Receiving Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.receivingPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Requesting Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.requestingPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Receiving Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.receivingPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Version:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processVersion)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'IDGeneratorEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.partyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.partyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Version:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processVersion)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Service Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.serviceName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Issued Certificate:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.issuedCertificate)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventSubtitle}>
                          Starting Values
                        </Text>
                        {s.startingValues?.length > 0 ?
                          <>
                            {s.startingValues?.map((s, i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                            })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventSubtitle}>Result</Text>
                        {s.result?.length > 0 ?
                          <>
                            {s.result?.map((s, i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                            })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventTextTitle}>
                          Supporting Information:
                        </Text>
                        {s.supportingInformation?.length > 0 ?
                          <>
                            {s.supportingInformation?.map((s,i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                            })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text><Text style={styles.eventText}>
                          {' '}
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'DataCustodyRequestedEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          New Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.newCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          New Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.newCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'RegistrationEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'AssetRegistrationEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registry)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Provider:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryProvider)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Environment:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryEnvironment)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry API Version:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryAPIVersion)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                default:
                  console.log(
                    'Step not set on the FE -> check file WorkflowSteps.jsx'
                  );
              }
            } else if (s.content !== undefined && !s.isError) {
              return (
                <View style={styles.col} key={index}>
                  <View style={styles.section}>
                    <Text style={styles.eventTitleNotable}>
                      Notable Event
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.eventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Title:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.title)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Description:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.description)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Related Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.relatedEventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Name:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyName)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Message:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.content)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Payload:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.payload)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Created at:
                    </Text>
                    <Text style={styles.eventText}>
                      
                      {s.createdAt.split('T', 1)[0] +
                        ' ' +
                        s.createdAt.split('T', 2)[1].split('.', 1) +
                        ' (GMT)'}
                    </Text>
                  </View>
                </View>
              );
            } else if (s.content !== undefined && s.isError) {
              return (
                <View style={styles.col} key={index}>
                  <View style={styles.section}>
                    <Text style={styles.eventTitleError}>
                      Notable Event Error
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.eventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Title:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.title)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Description:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.description)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Related Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.relatedEventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Name:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyName)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Message:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.content)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Payload:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.payload)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Created at:
                    </Text>
                    <Text style={styles.eventText}>
                      {s.createdAt.split('T', 1)[0] +
                        ' ' +
                        s.createdAt.split('T', 2)[1].split('.', 1) +
                        ' (GMT)'}
                    </Text>
                  </View>
                </View>
              );
            } else {
              return (
                <View style={styles.col} key={index}>
                  <View style={styles.section}>
                    <Text style={styles.eventTitle}>
                      Restatement Event
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.eventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Title:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.title)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Description:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.description)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Name:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyName)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Payload:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.payload)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Created at:
                    </Text>
                    <Text style={styles.eventText}>
                      {s.createdAt.split('T', 1)[0] +
                        ' ' +
                        s.createdAt.split('T', 2)[1].split('.', 1) +
                        ' (GMT)'}
                    </Text>
                  </View>
                </View>
              );
            }
          })
        }
        <View style={styles.pdfTitle}>
          {eventsInactives?.length ? (
            <Text style={styles.eventText}>Inactive Events:</Text>
          ) : (
            <></>
          )}
        </View>
        {
          // eslint-disable-next-line array-callback-return
          eventsInactives?.map((s, index) => {
            if (s['@type']) {
              switch (s['@type']) {
                case 'DataCustodyReceivedEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          New Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.newCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          New Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.newCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'ContextualizationEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.partyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.partyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Version:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processVersion)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Service Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.serviceName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Issued Certificate:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.issuedCertificate)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventSubtitle}>
                          Starting Values
                        </Text>
                        {s.startingValues.length > 0 ?
                          <>
                            {s.startingValues?.map((s, i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                          })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventSubtitle}>Result</Text>
                        {s.result.length > 0 ?
                          <>
                            {s.result?.map((s, i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                            })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventTextTitle}>
                          Supporting Information:
                        </Text>
                        {s.supportingInformation.length > 0 ?
                          <>
                            {s.supportingInformation?.map((s, i) => {
                              return (
                                <div key={i}>
                                  <Text style={styles.eventTextTitle}>
                                    {escapeEmptyDetail(s.name)}:
                                  </Text>
                                  <Text style={styles.eventText}>
                                    {escapeEmptyDetail(s.value)}
                                  </Text>
                                </div>
                              );
                            })}
                          </>
                        :
                          <Text style={styles.eventTextTitle}>
                            -
                          </Text>
                        }
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'ContextualizationRequestEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Requesting Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.requestingPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Receiving Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.receivingPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Requesting Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.requestingPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Receiving Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.receivingPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Process Version:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.processVersion)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                  case 'IDGeneratorEvent':
                    return (
                      <View style={styles.col} key={index}>
                        <View style={styles.section}>
                          <Text style={styles.eventTitle}>
                            { readableStepName(s['@type']) }
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Event Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.eventId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Title:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.title)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Description:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.description)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.partyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Process Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.processName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.partyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Process Version:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.processVersion)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Service Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.serviceName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Issued Certificate:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.issuedCertificate)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Payload:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.payload)}
                          </Text>
                          <Text style={styles.eventSubtitle}>
                            Starting Values
                          </Text>
                          {s.startingValues?.length > 0 ?
                            <>
                              {s.startingValues?.map((s, i) => {
                                return (
                                  <div key={i}>
                                    <Text style={styles.eventTextTitle}>
                                      {escapeEmptyDetail(s.name)}:
                                    </Text>
                                    <Text style={styles.eventText}>
                                      {escapeEmptyDetail(s.value)}
                                    </Text>
                                  </div>
                                );
                              })}
                            </>
                          :
                            <Text style={styles.eventTextTitle}>
                              -
                            </Text>
                          }
                          <Text style={styles.eventSubtitle}>Result</Text>
                          {s.result?.length > 0 ?
                            <>
                              {s.result?.map((s, i) => {
                                return (
                                  <div key={i}>
                                    <Text style={styles.eventTextTitle}>
                                      {escapeEmptyDetail(s.name)}:
                                    </Text>
                                    <Text style={styles.eventText}>
                                      {escapeEmptyDetail(s.value)}
                                    </Text>
                                  </div>
                                );
                              })}
                            </>
                          :
                            <Text style={styles.eventTextTitle}>
                              -
                            </Text>
                          }
                          <Text style={styles.eventTextTitle}>
                            Supporting Information:
                          </Text>
                          {s.supportingInformation?.length > 0 ?
                            <>
                              {s.supportingInformation?.map((s,i) => {
                                return (
                                  <div key={i}>
                                    <Text style={styles.eventTextTitle}>
                                      {escapeEmptyDetail(s.name)}:
                                    </Text>
                                    <Text style={styles.eventText}>
                                      {escapeEmptyDetail(s.value)}
                                    </Text>
                                  </div>
                                );
                              })}
                            </>
                          :
                            <Text style={styles.eventTextTitle}>
                              -
                            </Text>
                          }
                          <Text style={styles.eventTextTitle}>
                            Created at:
                          </Text><Text style={styles.eventText}>
                            {' '}
                            {s.createdAt.split('T', 1)[0] +
                              ' ' +
                              s.createdAt.split('T', 2)[1].split('.', 1) +
                              ' (GMT)'}
                          </Text>
                        </View>
                      </View>
                    );
                  case 'DataCustodyRequestedEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventText}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventText}>
                          Old Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventText}>
                          New Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.newCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventText}>
                          Old Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventText}>
                          New Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.newCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventText}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventText}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'RegistrationEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                case 'AssetRegistrationEvent':
                  return (
                    <View style={styles.col} key={index}>
                      <View style={styles.section}>
                        <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Event Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.eventId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Title:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.title)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Description:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.description)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Old Custodian Party Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.oldCustodianPartyName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Id:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryId)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Name:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryName)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registry)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Provider:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryProvider)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry Environment:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryEnvironment)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Registry API Version:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.registryAPIVersion)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Payload:
                        </Text>
                        <Text style={styles.eventText}>
                          {escapeEmptyDetail(s.payload)}
                        </Text>
                        <Text style={styles.eventTextTitle}>
                          Created at:
                        </Text>
                        <Text style={styles.eventText}>
                          {s.createdAt.split('T', 1)[0] +
                            ' ' +
                            s.createdAt.split('T', 2)[1].split('.', 1) +
                            ' (GMT)'}
                        </Text>
                      </View>
                    </View>
                  );
                default:
                  console.log(
                    'Step not set on the FE -> check file WorkflowSteps.jsx'
                  );
              }
            } else if (s.content !== undefined && !s.isError) {
              return (
                <View style={styles.col} key={index}>
                  <View style={styles.section}>
                    <Text style={styles.eventTitleNotable}>
                      Notable Event
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.eventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Title:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.title)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Description:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.description)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Related Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.relatedEventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Name:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyName)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Message:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.content)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Payload:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.payload)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Created at:
                    </Text>
                    <Text style={styles.eventText}>
                      {s.createdAt.split('T', 1)[0] +
                        ' ' +
                        s.createdAt.split('T', 2)[1].split('.', 1) +
                        ' (GMT)'}
                    </Text>
                  </View>
                </View>
              );
            } else if (s.content !== undefined && s.isError) {
              return (
                <View style={styles.col} key={index}>
                  <View style={styles.section}>
                    <Text style={styles.eventTitleError}>
                      Notable Event Error
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.eventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Title:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.title)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Description:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.description)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Related Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.relatedEventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Name:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyName)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Message:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.content)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Payload:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.payload)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Created at:
                    </Text>
                    <Text style={styles.eventText}>
                      {s.createdAt.split('T', 1)[0] +
                        ' ' +
                        s.createdAt.split('T', 2)[1].split('.', 1) +
                        ' (GMT)'}
                    </Text>
                  </View>
                </View>
              );
            } else {
              return (
                <View style={styles.col} key={index}>
                  <View style={styles.section}>
                    <Text style={styles.eventTitle}>Restatement Event</Text>
                    <Text style={styles.eventTextTitle}>
                      Event Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.eventId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Title:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.title)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Description:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.description)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Id:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyId)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Party Name:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.partyName)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Payload:
                    </Text>
                    <Text style={styles.eventText}>
                      {escapeEmptyDetail(s.payload)}
                    </Text>
                    <Text style={styles.eventTextTitle}>
                      Created at:
                    </Text>
                    <Text style={styles.eventText}>
                      {s.createdAt.split('T', 1)[0] +
                        ' ' +
                        s.createdAt.split('T', 2)[1].split('.', 1) +
                        ' (GMT)'}
                    </Text>
                  </View>
                </View>
              );
            }
          })
        }
      </Page>
    </Document>
  );

  return <>{EventsToPDF()}</>;
};

export default EventsPDF;
