import React from 'react';
import Logo from '../../images/fiutur_logo.png';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();

  var main_tab = localStorage.getItem('main_tab');

  const goToWorkflows = () => {
    window.localStorage.setItem('main_tab', 'workflows');
    navigate(`/`);
  }

  const goToCertificates = () => {
    window.localStorage.setItem('main_tab', 'certificates');
    navigate(`/master-certificates`);
  }

  return (
    <div className='header'>
      <img src={Logo} alt='Logo' className='logo' />
      <div className='header-links'>
        <span className={`header-link-item ${main_tab === 'workflows' ? 'selected':''}`} onClick={goToWorkflows}>Workflows</span>
        <span className={`header-link-item ${main_tab === 'certificates' ? 'selected':''}`} onClick={goToCertificates}>Certificates</span>
      </div>
      <div className='user_menu'>
        <span className='item'>{user ? user.email : ''}</span>
        <span className='item'> | </span>
        <span className='item clickable' onClick={() => logout()} >Logout</span>
      </div>
    </div>

  // <Grid container className='header'>
  //   <Grid item md={2}>
  //     <img src={Logo} alt='Logo' className='logo' />
  //   </Grid>
  //   <Grid item md={6}>
  //     <div className='header-links'>
  //       <span className={`header-link-item ${main_tab === 'workflows' ? 'selected':''}`} onClick={goToWorkflows}>Workflows</span>
  //       <span className={`header-link-item ${main_tab === 'certificates' ? 'selected':''}`} onClick={goToCertificates}>Certificates</span>
  //     </div>
  //   </Grid>
  //   <Grid item md={4}>
  //     <div className='user_menu'>
  //       <span className='item'>{user ? user.email : ''}</span>
  //       <span className='item'> | </span>
  //       <span className='item clickable' onClick={() => logout()} >Logout</span>
  //     </div>
  //   </Grid>
  // </Grid>
  );
};

export default Header;
