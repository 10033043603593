import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { TextField, MenuItem } from '@mui/material';
import { Loader } from 'semantic-ui-react';
import { Grid, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { ToggleButtonGroup, ToggleButton, Pagination } from '@mui/material/';

import ErrorMessage from '../../components/Error/Error';
import StripedTable from '../../components/Table/StripedTable.jsx';
import Arrow from '../../images/Arrow.svg';
import { useUserState } from '../../context/UserContext';
import WorkflowStepsToPDF from '../../pdfs/WorkflowSteps.jsx';
import { getNextStep, listPayloads, listWorkflow, listWorkflowCounters } from '../../api/api';

import { DataCustodyRequestedStepModel } from '../../models/steps/DataCustodyRequestedStep';
import { DataCustodyReceivedStepModel } from '../../models/steps/DataCustodyReceivedStep';
import { ContextualizationRequestStepModel } from '../../models/steps/ContextualizationRequestStep';
import { ContextualizationStepModel } from '../../models/steps/ContextualizationStep';
import { IDGeneratorStepModel } from '../../models/steps/IDGeneratorRequestStep';
import { RegistrationStepModel } from '../../models/steps/RegistrationStep';
import { AssetRegistrationStepModel } from '../../models/steps/AssetRegistrationStep';
import { checkedOptions } from '../../constants/workflows';
import { readableStepName, escapeEmptyDetail, escapeCommasForCsv, useDidMountEffect } from '../../utils/utils';

//styling tooltip
const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '12px',
    top: '-6px',
  },
})(Tooltip)

const Workflows = () => {
  const navigate = useNavigate();
  const user = useUserState();
  const params = useParams();

  const myRef = useRef();
  const hiddenRef = useRef();
  const payloadIdRef = useRef(null);

  const [searchPayloadId, setSearchPayloadId] = useState();
  const [searchIngestDateFrom, setSearchIngestDateFrom] = useState(null);
  const [searchIngestDateTo, setSearchIngestDateTo] = useState(null);
  const [searchLastUpdateFrom, setSearchLastUpdateFrom] = useState(null);
  const [searchLastUpdateTo, setSearchLastUpdateTo] = useState(null);
  const [ingestDateFromError, setIngestDateFromError] = useState(null);
  const [ingestDateToError, setIngestDateToError] = useState(null);
  const [lastUpdateFromError, setLastUpdateFromError] = useState(null);
  const [lastUpdateToError, setLastUpdateToError] = useState(null);
  const [statusSelected, setStatusSelected] = useState('');
  const [payloadsList, setPayloadsList] = useState([]);
  const [stepSelected, setStepSelected] = useState();
  const [workflow, setWorkflow] = useState();
  const [stepCounter, setStepCounter ] = useState();
  const [loading, setLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  // Pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.localStorage.setItem('main_tab', 'workflows');

    console.log('CONTEXT_API_UPDATED -> ', user);
    let riid = params.workflowId;

    if (user.workflowSelected || riid) {
      // getting workflow data to display steps details
      listWorkflow(user.workflowSelected ? user.workflowSelected : riid)
        .then((res) => {
          setWorkflow(res.data);
          listWorkflowCounters(user.workflowSelected ? user.workflowSelected : riid)
            .then((res) => {
              setStepCounter(res.data);
            })
        })
        .catch((err) => {
          setHasError(true);
          setErrorStatus(err.response.status);
          setErrorDetail(err.response.data.detail);
        })
        getPayloads(user.workflowSelected);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDidMountEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getPayloads(user.workflowSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  useDidMountEffect(() => {
    getPayloads(user.workflowSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getPayloads = (workflow) => {
    setLoading(true);
    let riid = params.workflowId;

  // getting workflow data to display list all payloads
    listPayloads(workflow ? workflow : riid, itemsPerPage, page-1, searchPayloadId, searchIngestDateFrom, searchIngestDateTo, searchLastUpdateFrom, searchLastUpdateTo, statusSelected)
      .then((res) => {
        setPayloadsList(res.data.content);

        let payloadList = res.data.content;

        function getNext(id, el) {
          return new Promise((resolve) => {
            getNextStep(id).then(
              (step) => {
                el = {...el, nextStep: step.data};
                resolve(el);
              })
          });
        }

        function getNextStepList() {
          const promises = [];
          
          for (let el of payloadList) {
            if(el.status === 'ACTIVE') {
              promises.push(getNext(el.payloadId, el));
            } else {
              promises.push(el);
            }
          }
          
          Promise.all(promises)
            .then((results) => {
              setPayloadsList(results);
            })
            .catch((e) => {
                // Handle errors here
            });
        }
        
        getNextStepList();
        setNoOfPages(res.data.totalPages);

      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        finishLoader();
      })
  }

  // watching filters
  useDidMountEffect(() => {
    let ingestDateFrom = searchIngestDateFrom ? dayjs(searchIngestDateFrom).format('YYYY-MM-DD') : '';
    let ingestDateTo = searchIngestDateTo ? dayjs(searchIngestDateTo).format('YYYY-MM-DD') : '';
    let lastUpdateFrom = searchLastUpdateFrom ? dayjs(searchLastUpdateFrom).format('YYYY-MM-DD') : '';
    let lastUpdateTo = searchLastUpdateTo ? dayjs(searchLastUpdateTo).format('YYYY-MM-DD') : '';

    const applyFilter = () => {
      getPayloads(user.workflowSelected);
    }
    if (ingestDateTo && ingestDateTo < ingestDateFrom) {
      setIngestDateToError('Ingest Date To should be greater that Ingest Date From')
    } else if (lastUpdateTo && lastUpdateTo < lastUpdateFrom) {
      setLastUpdateToError('Last Update To should be greater that Last Update From')
    } else 
      applyFilter();
      
  }, [
    searchPayloadId,
    searchIngestDateFrom,
    searchIngestDateTo,
    searchLastUpdateFrom,
    searchLastUpdateTo,
    statusSelected
  ])

  const handleItemsPerPage = (event, newAmount) => {
    setItemsPerPage(newAmount);
  }

  const handleChangePage = (event, value) => {
    setPage(value);
  }

  const finishLoader = () => {
    setLoading(false);
  }

  // export payload list
  const PayloadsCSV = (e) => {
    e.preventDefault();
    let headers = ['Payload Id,Ingest Date ,Last Update , Status'];
    let payloadsCsv = payloadsList.reduce((acc, row) => {
      const { payloadId, createdAt, updatedAt, status } = row;

      let creationDate = createdAt?.split('T', 1)[0];
      let updateDate = updatedAt?.split('T', 1)[0];
      let creationTime = createdAt?.split('T', 2)[1].split('.', 1);
      let updateTime = updatedAt?.split('T', 2)[1].split('.', 1);

      acc.push([
        payloadId,
        creationDate + ' ' + creationTime + ' GMT',
        updateDate + ' ' + updateTime + ' GMT',
        status,
      ]);
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...payloadsCsv].join('\n'),
      fileName: (workflow.title).replaceAll('.','_') + '_payloads',
      fileType: 'text/csv',
    });
  }

  // export workflow csv
  const WorkflowCSV = (e) => {
    e.preventDefault();

    let headers = ['Type, Title, Description, Order, Payloads Amount, New Custodian Party Name, New Custodian Party Id, Old Custodian Party Name, Old Custodian Party Id, Requesting Party Name, Receiving Party Id, Receiving Party Name, Receiving Party Id, Process Name, Process Version, Party Name, Party Id, Registry Name, Registry Id'];

    let payloadsCsv = workflow?.steps.reduce((acc, row) => {
      const {
        title,
        description,
        order,
        payloadsAmount,
        newCustodianPartyName,
        newCustodianPartyId,
        oldCustodianPartyName,
        oldCustodianPartyId,
        requestingPartyName,
        requestingPartyId,
        receivingPartyName,
        receivingPartyId,
        processName,
        processVersion,
        partyName,
        partyId,
        registryName,
        registryId,
      } = row;
      acc.push(
        [
          row['@type'],
          escapeCommasForCsv(title),
          escapeCommasForCsv(description),
          escapeCommasForCsv(order),
          escapeCommasForCsv(payloadsAmount),
          escapeCommasForCsv(newCustodianPartyName),
          escapeCommasForCsv(newCustodianPartyId),
          escapeCommasForCsv(oldCustodianPartyName),
          escapeCommasForCsv(oldCustodianPartyId),
          escapeCommasForCsv(requestingPartyName),
          escapeCommasForCsv(requestingPartyId),
          escapeCommasForCsv(receivingPartyName),
          escapeCommasForCsv(receivingPartyId),
          escapeCommasForCsv(processName),
          escapeCommasForCsv(processVersion),
          escapeCommasForCsv(partyName),
          escapeCommasForCsv(partyId),
          escapeCommasForCsv(registryName),
          escapeCommasForCsv(registryId),
        ].join(',')
      );
      return acc;
    }, [])

    downloadFile({
      data: [...headers, ...payloadsCsv].join('\n'),
      fileName: (workflow.title).replaceAll('.','_'),
      fileType: 'text/csv',
    });
  }

  // export workflow json
  const WFStepsToJson = (e) => {
    e.preventDefault();
    downloadFile({
      data: JSON.stringify(workflow?.steps),
      fileName: (workflow.title).replaceAll('.','_') + '.json',
      fileType: 'text/json',
    });
  }

  // export workflow pdf
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  const selectEvent = (step) => {
    // if (
    //   window.pageYOffset + window.innerHeight <= hiddenRef.current.offsetTop ||
    //   !stepSelected
    // ) {
      myRef.current.scrollIntoView();
    // }
    setStepSelected(step);
  }

  const payloadIdSelect = (payload) => {
    navigate(`/payloads/${payload.payloadId}`);
  }

  const payloadCounterIndex = (index) => {
    let found = stepCounter?.find((el) => el.order -1 === index);
    return found ? found.count : 0;
  }

  const renderFlowchart = (index) => {
    let stepsQty = workflow?.steps?.length;

    return (
      <div className='workflow-wrapper'>
        {workflow?.steps.map((step, index) => (
          <div className='workflow-step' key={index}>
            <div
              className={` step-wrapper ${
                (index + 1) % 5 === 0 && index !== 1 ? 'last' : ''
              }
              ${step.order === stepSelected?.order ? 'selected' : ''}
              `}
            >
              <figure>
                {index > 0 && (index +1) % 6 !== 0 &&
                  <span
                    className={`${index + 1 <= stepsQty ? 'arrow' : ''}`}
                  ></span>
                }
              </figure>
              <CustomTooltip
                placement={'top'}
                arrow
                title={`Click to see ${ step.title ? step.title : readableStepName(step['@type']) } details`}
              >
                <div
                  className='step'
                  onClick={() => {
                    selectEvent(step)
                  }}
                >
                  <p>
                    { step.title ?
                      step.title
                    :
                      readableStepName(step['@type'])
                    }
                    {<span><br/>({payloadCounterIndex(index)})</span>}
                  </p>
                </div>
              </CustomTooltip>
              
            </div>
            {(index + 1) % 5 === 0 && index !== 1 && index + 1 < stepsQty ? (
              <figure
                className={`${
                  (index + 1) % 5 === 0 && index !== 1 && index + 1 < stepsQty
                    ? 'arrow-long-wrapper'
                    : ''
                }`}
              >
                <span
                  className={`${
                    (index + 1) % 5 === 0 && index !== 1 ? 'arrow-long' : ''
                  }`}
                ></span>
              </figure>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    );
  }

  const getStepObj = (step) => {
    switch (step['@type']) {
      case 'DataCustodyRequestedStep':
        return <>{DataCustodyRequestedStepModel(step)}</>;
      case 'DataCustodyReceivedStep':
        return <>{DataCustodyReceivedStepModel(step)}</>;
      case 'ContextualizationRequestStep':
        return <>{ContextualizationRequestStepModel(step)}</>;
      case 'ContextualizationStep':
        return <>{ContextualizationStepModel(step)}</>;
      case 'IDGeneratorStep':
        return <>{IDGeneratorStepModel(step)}</>;
      case 'RegistrationStep':
        return <>{RegistrationStepModel(step)}</>;
      case 'AssetRegistrationStep':
        return <>{AssetRegistrationStepModel(step)}</>;
      default:
        console.log('Step not set on the FE -> check Payloads.jsx file');
        break;
    }
  }

  return (
    <div className='payload'>
      {!workflow && !hasError ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <>
              <section className='search-result'>
                <p
                  className='link'
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  Workflows
                </p>
                <p>
                  <img src={Arrow} alt='arrow'></img>
                </p>
                <p>{workflow.title}</p>
              </section>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  marginBottom: '20px',
                  display: 'block',
                }}
              >
                <p className='details-title'>
                  {escapeEmptyDetail(workflow.title)}
                </p>
                <p>
                  <span className='detail-title'>RIID: </span>
                  {escapeEmptyDetail(workflow.riid)}
                </p>
                <p>
                  <span className='detail-title'>Description: </span>
                  {escapeEmptyDetail(workflow.description)}
                </p>
                <section className='flowchart'>{renderFlowchart()}</section>
                <span ref={myRef}></span>
                <section className='export-btn-wrapper'>
                  <PDFDownloadLink
                    document={WorkflowStepsToPDF(workflow)}
                    fileName={escapeEmptyDetail((workflow.title).replaceAll('.','_'))}
                  >
                    <Tooltip placement={'top'} arrow title='Export PDF'>
                      <button className='sm-btn'> pdf </button>
                    </Tooltip>
                  </PDFDownloadLink>
                  <Tooltip placement={'top'} arrow title='Export JSON'>
                    <button className='sm-btn json' onClick={WFStepsToJson}>
                      {'{ }'}
                    </button>
                  </Tooltip>
                  <Tooltip placement={'top'} arrow title='Export CSV'>
                    <button className='sm-btn' onClick={WorkflowCSV}>
                      csv
                    </button>
                  </Tooltip>
                </section>
              </Box>

              <span ref={hiddenRef}></span>

              {stepSelected ? 
                <Box
                  sx={{
                    width: '100%',
                    border: '1px solid lightgrey',
                    borderRadius: '4px',
                    padding: '20px',
                    marginBottom: '20px',
                    display: 'block',
                  }}>
                    {getStepObj(stepSelected)}
                </Box>
              : ''}

              <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  marginBottom: '20px',
                  display: 'block',
                }}
              >
                <p className='details-title'>
                  <b>Payloads</b>
                </p>

                <div className='Home'>
                  <section className='filter-section'>
                    {/* <SearchBar
                      placeholder={'Search by Payload Id'}
                      value={searchPayloadId}
                      onChange={(searchVal) =>
                        setSearchPayloadId(searchVal.toLowerCase())
                      }
                      onCancelSearch={() => setSearchPayloadId('')}
                    /> */}
                    <TextField
                      type='search'
                      label='Search by Payload Id'
                      value={searchPayloadId}
                      onChange={(searchVal) => {
                        setTimeout(() => {
                          payloadIdRef.current.focus();
                        }, 500);
                        setSearchPayloadId(searchVal.target.value);
                      }
                      }
                      inputRef={payloadIdRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className='datepicker-wrapper'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format='MM-DD-YYYY'
                          label='Ingest date from'
                          value={dayjs(dayjs(searchIngestDateFrom).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(searchIngestDateFrom) : null}
                          onChange={(newValue) => {
                            setTimeout(function(){
                              if (newValue === '' || newValue === null) {
                                setSearchIngestDateFrom(null);
                                setIngestDateFromError(null);
                              } else if (!(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())){
                                setIngestDateFromError('Invalid date');
                              } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).year() < 1900){
                                setIngestDateFromError('Year should be greater than 1899');
                              } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD')) {
                                setIngestDateFromError('Ingest Date From should be before today')
                              } else if(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                                setSearchIngestDateFrom(dayjs(newValue).format('YYYY-MM-DD'));
                                setIngestDateFromError(null);
                              }
                            }, 500);
                          }}
                          disableFuture
                          slotProps={{
                            actionBar: { actions: ["clear"], position: "left" }
                          }}
                        />
                      </LocalizationProvider>
                        <p>
                          {ingestDateFromError}
                        </p>
                    </div>
                    <div className='datepicker-wrapper'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format='MM-DD-YYYY'
                          label='Ingest date to'
                          value={dayjs(dayjs(searchIngestDateTo).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(searchIngestDateTo) : null}
                          onChange={(newValue) => {
                            setTimeout(function(){
                              if (newValue === '' || newValue === null) {
                                setSearchIngestDateTo(null);
                                setIngestDateToError(null);
                              } else if (!(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())){
                                setIngestDateToError('Invalid date');
                              } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).year() < 1900){
                                setIngestDateToError('Year should be greater than 1899');
                              } else if(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                                setSearchIngestDateTo(dayjs(newValue).format('YYYY-MM-DD'));
                                setIngestDateToError(null);
                              }
                            }, 500);
                          }}
                          slotProps={{
                            actionBar: { actions: ["clear"], position: "left" }
                          }}
                        />
                      </LocalizationProvider>
                      <p>
                        {ingestDateToError}
                      </p>
                    </div>
                    <div className='datepicker-wrapper'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format='MM-DD-YYYY'
                          label='Last update from'
                          value={dayjs(dayjs(searchLastUpdateFrom).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(searchLastUpdateFrom) : null}
                          onChange={(newValue) => {
                            setTimeout(function(){
                              if (newValue === '' || newValue === null) {
                                setSearchLastUpdateFrom(null);
                                setLastUpdateFromError(null);
                              } else if (!(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())){
                                setLastUpdateFromError('Invalid date');
                              } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).year() < 1900){
                                setLastUpdateFromError('Year should be greater than 1899');
                              } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD')) {
                                setLastUpdateFromError('Last Update From should be before today')
                              } else if(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                                setSearchLastUpdateFrom(dayjs(newValue).format('YYYY-MM-DD'));
                                setLastUpdateFromError(null);
                              }
                            }, 500);
                          }}
                          disableFuture
                          slotProps={{
                            actionBar: { actions: ["clear"], position: "left" }
                          }}
                        />
                      </LocalizationProvider>
                      <p>
                        {lastUpdateFromError}
                      </p>
                    </div>
                    <div className='datepicker-wrapper'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format='MM-DD-YYYY'
                          label='Last update to'
                          value={dayjs(dayjs(searchLastUpdateTo).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(searchLastUpdateTo) : null}
                          onChange={(newValue) => {
                            setTimeout(function(){
                              if (newValue === '' || newValue === null) {
                                setSearchLastUpdateTo(null);
                                setLastUpdateToError(null);
                              } else if (!(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())){
                                setLastUpdateToError('Invalid date');
                              } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).year() < 1900){
                                setLastUpdateToError('Year should be greater than 1899');
                              } else if(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                                setSearchLastUpdateTo(dayjs(newValue).format('YYYY-MM-DD'));
                                setLastUpdateToError(null);
                              }
                            }, 500);
                          }}
                          slotProps={{
                            actionBar: { actions: ["clear"], position: "left" }
                          }}
                        />
                      </LocalizationProvider>
                      <p>
                        {lastUpdateToError}
                      </p>
                    </div>
                    
                    <TextField
                      select
                      label='Status'
                      placeholder='Status'
                      value={statusSelected}
                      onChange={(valueSelected) => {
                        setStatusSelected(valueSelected.target.value);
                      }}
                      >
                        <MenuItem key={'all'} value=''>All</MenuItem>
                        {checkedOptions.map(el =>
                          <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                        )}
                    </TextField>
                  </section>
                  {!loading ? (
                    <>
                      <StripedTable
                        rowsClickable
                        headings={[
                          'Payload Id',
                          'Ingest Date',
                          'Last Update',
                          'Status',
                          'Manual Step'
                        ]}
                        rows={payloadsList
                          // ?.slice(
                          //   (page - 1) * itemsPerPage,
                          //   page * itemsPerPage
                          // )
                          .map((payload, index) => {
                            if (payloadsList.length) {
                              return {
                                elements: [
                                  <p>{payload.payloadId}</p>,
                                  <p>
                                    {dayjs(
                                      payload.createdAt.split('T', 1).toString()
                                    ).format('MM-DD-YYYY') +
                                      ' ' +
                                      payload.createdAt
                                        .split('T', 2)[1]
                                        .split('.', 1) +
                                      ' (GMT)'}
                                  </p>,
                                  <p>
                                    {dayjs(
                                      payload.updatedAt.split('T', 1).toString()
                                    ).format('MM-DD-YYYY') +
                                      ' ' +
                                      payload.updatedAt
                                        .split('T', 2)[1]
                                        .split('.', 1) +
                                      ' (GMT)'}
                                  </p>,
                                  <p
                                    className={` status ${
                                      payload.status === 'COMPLETED'
                                        ? 'completed'
                                        : payload.status === 'STOPPED'
                                        ? 'stopped'
                                        : payload.status === 'TRANSACTED'
                                        ? 'transacted'
                                        : payload.status === 'RETIRED'
                                        ? 'retired'
                                        : 'active'
                                    }`}
                                  >
                                    {payload.status}
                                  </p>,
                                  <p>{payload.status === 'ACTIVE' && payload.nextStep && payload.nextStep.isManual === true ? 'PENDING MANUAL STEP' : '-'}</p>
                                ],
                                onClick: () => {
                                  payloadIdSelect(payload)
                                },
                              }
                            } else {
                              return {
                                elements: [],
                              }
                            }
                          })}
                      />
                      <Grid container spacing={2}>
                        <Grid item md={4}/>
                        <Grid item md={4}>
                          <Box
                            component='div'
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '3rem 0'
                            }}
                            >
                            <Pagination
                              count={noOfPages}
                              page={page}
                              onChange={handleChangePage}
                              defaultPage={0}
                              color='primary'
                              size='small'
                            />
                          </Box>
                        </Grid>
                        {payloadsList?.length > 0 &&
                          <Grid item md={4} p={2}>
                            <Box
                              component='div'
                              sx={{
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'center',
                                padding: '3rem 0'
                              }}
                              className='perpage'>
                              <Tooltip
                                placement={'right'}
                                arrow
                                title={<span>View per page</span>}
                              >
                                <ToggleButtonGroup
                                  value={itemsPerPage}
                                  exclusive
                                  onChange={handleItemsPerPage}
                                  aria-label='text alignment'
                                  size='small'
                                >
                                  <ToggleButton value={10} aria-label='left aligned'>
                                    10
                                  </ToggleButton>
                                  <ToggleButton value={50} aria-label='centered'>
                                    50
                                  </ToggleButton>
                                  <ToggleButton value={100} aria-label='justified'>
                                    100
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Tooltip>
                            </Box>
                          </Grid>
                        }
                      </Grid>
                      <section className='export-btn-wrapper'>
                        {payloadsList.length ? (
                          <Tooltip placement={'top'} arrow title='Export CSV'>
                            <button onClick={PayloadsCSV} className='sm-btn'>
                              csv
                            </button>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </section>
                    </>
                  ) : (
                    <div className='empty-table'>
                      <Loader active indeterminate size='small' />
                    </div>
                  )}
                </div>
              </Box>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Workflows
