import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { TextField, Grid } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ErrorMessage from '../../components/Error/Error';
import { addGeneralManualStep, addNotableEvent } from '../../api/api'
import { escapeEmptyDetail } from '../../utils/utils';
import { validationSchema as generalStepValidationSchema } from '../../constants/generalManualStep';
import { validationSchema as notableEventValidationSchema } from '../../constants/notableEventManualStep';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import DetailItem from '../../components/DetailItem/DetailItem';

const GeneralManualStep = (props) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [approval, setApproval] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  const handleSubmit = (values, formikHelpers) => {

    delete values.title;
    delete values.description;

    if(approval === 'true') {
      delete values.content;

      addGeneralManualStep(props.payloadId, values)
      .then(() => {
        navigate(0);
      })
      .catch((err) => {
        if (err.response.data['error.key'] !== null) {
          formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
          formikHelpers.setSubmitting(false);
        } else if(err.response.data.fieldErrors !== null) {
          let errors = {};
          err.response.data.fieldErrors.forEach(el => {
            errors[el.field] = el.message;
          })
          formikHelpers.setErrors(errors);
          formikHelpers.setSubmitting(false);
        } else {
          setHasError(true)
          setErrorStatus(err.response.status)
          setErrorDetail(err.response.data.detail);
        }
        // formikHelpers.setSubmitting(false);
      })
      .finally(() => {
      setLoading(false);
      });
      
    } else if(approval === 'false') {

      values = {...values, isError: true};
      delete values['@type'];
      delete values.order;
      delete values.isManual;
      
      addNotableEvent(props.payloadId, values)
        .then(() => {
          navigate(0);
        })
        .catch((err) => {
          if (err.response.data['error.key'] !== null) {
            formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
            formikHelpers.setSubmitting(false);
          } else if(err.response.data.fieldErrors !== null) {
            let errors = {};
            err.response.data.fieldErrors.forEach(el => {
              errors[el.field] = el.message;
            })
            formikHelpers.setErrors(errors);
            formikHelpers.setSubmitting(false);
          } else {
            setHasError(true)
            setErrorStatus(err.response.status)
            setErrorDetail(err.response.data.detail);
          }
          // formikHelpers.setSubmitting(false);
        })
        .finally(() => {
        setLoading(false);
      });
    }
  }   

  return (
    <>
      {loading ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
        { hasError ? (
            <Modal
              open={props.open}
              onClose={props.onClose}
              className='formModal'
            >
            <Modal.Header>{props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)} Manual Step</Modal.Header>
              <Modal.Content className='formModal-content'>
                <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
              </Modal.Content>
              <Modal.Actions>
                  <Button onClick={props.onClose}>
                    Close
                  </Button>
                </Modal.Actions>
            </Modal>
          ) : (
            <Formik
              initialValues={{
                payload: props.payload64 ?? '',
                '@type': props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)+'Event',
                order: props.workflowStep.order ?? '',
                isManual: props.workflowStep.isManual ?? '',
                content: '',
                title: props.workflowStep.title,
                description: props.workflowStep.description
              }}
              validationSchema={approval === 'true' ? generalStepValidationSchema : notableEventValidationSchema}
              onSubmit={handleSubmit}
            >
              { formProps => ( 
                <form onSubmit={formProps.handleSubmit} noValidate >
                  <Modal
                      open={props.open}
                      onClose={props.onClose}
                      className='formModal'
                  >
                    <Modal.Header>{props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)} Manual Step</Modal.Header>
                    <Modal.Content className='formModal-content' >
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <DetailItem title='Title' content={escapeEmptyDetail(formProps.values.title)} />
                        </Grid>
                        <Grid item md={12}>
                          <DetailItem title='Description' content={escapeEmptyDetail(formProps.values.description)} />
                        </Grid>
                        { !props.decodedPayload &&
                          <Grid item md={12}>
                            <TextField
                              required
                              fullWidth={true}
                              label='Payload'
                              id='payload'
                              name='payload'
                              placeholder='Payload'
                              value={formProps.values.payload}
                              onChange={formProps.handleChange}
                              error={formProps.touched.payload && Boolean(formProps.errors.payload)}
                              helperText={formProps.touched.payload && formProps.errors.payload}
                            />
                          </Grid>
                        }
                        { props.decodedPayload &&
                          <Grid item md={12}>
                            <DecodedPayload decodedPayload={props.decodedPayload} />
                          </Grid>
                        }
                        <Grid item md={12}>
                          <ToggleButtonGroup
                            value={approval}
                            exclusive
                            onChange={(e) => setApproval(e.target.value)}
                            aria-label='action'
                          >
                            <ToggleButton value='true'>
                              Approve
                            </ToggleButton>
                            <ToggleButton value='false'>
                              Reject
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                        { approval === 'false' && 
                          <Grid item md={12}>
                            <TextField
                                required
                                fullWidth={true}
                                label='Reason'
                                id='content'
                                name='content'
                                placeholder='Reason'
                                value={formProps.values.content}
                                onChange={formProps.handleChange}
                                error={formProps.touched.content && Boolean(formProps.errors.content)}
                                helperText={formProps.touched.content && formProps.errors.content}
                            />
                          </Grid>
                        }
                      </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button disabled={formProps.isSubmitting} onClick={props.onClose}>Cancel</Button>
                      <Button disabled={formProps.isSubmitting || approval === undefined} className='actionButton' type='submit' onClick={formProps.handleSubmit}>Submit</Button>
                    </Modal.Actions>
                  </Modal>
                </form>
              )} 
            </Formik>
          )}
        </>
      )}
    </>
  )
}

export default GeneralManualStep
