import { readableStepName, escapeEmptyDetail } from '../../utils/utils';
import DetailItem from '../../components/DetailItem/DetailItem';

export const IDGeneratorStepModel = (step) => {
  return (
    <>
      <p className='details-title'>
        <b>
          { readableStepName(step['@type']) }
        </b>
      </p>
      <DetailItem title='Title' content={escapeEmptyDetail(step.title)} />
      <DetailItem title='Description' content={escapeEmptyDetail(step.description)} />
      <DetailItem title='Party Id' content={escapeEmptyDetail(step.partyId)} copy={step.partyId} />
      <DetailItem title='Party Name' content={escapeEmptyDetail(step.partyName)} />
      <DetailItem title='Process Name' content={escapeEmptyDetail(step.processName)} />
      <DetailItem title='Process Version' content={escapeEmptyDetail(step.processVersion)} />
    </>
  );
};
