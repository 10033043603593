import { withAuthenticationRequired } from '@auth0/auth0-react';
import Header from '../components/Header/Header';
import TabMenu from '../components/TabMenu/TabMenu';
import React from 'react';

export const ProtectedRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {});

  return (
    <>
      <Header />
      <TabMenu />
      <Component />
    </>
  );
};
