import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'semantic-ui-css/semantic.min.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { auth0_domain, auth0_clientid } from './constants/auth';

let clientId = auth0_clientid;
window.localStorage.setItem('main_tab', 'workflows');

ReactDOM.render(
  <Auth0Provider
    domain={auth0_domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    cacheLocation='localstorage'
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
