import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DecodedPayloadTable from './DecodedPayloadTable.jsx';
import DecodedPayloadObject from './DecodedPayloadObject.jsx';
import { escapeEmptyDetail } from '../../utils/utils';
import DetailItem from '../DetailItem/DetailItem.jsx';

const DecodedPayload = (props) => {

  const decodedPayload = props.decodedPayload;
  
  const payloadElements = () => {
    let elements = [];

      for (const [key, value] of Object.entries(decodedPayload)) {
        if (value && Array.isArray(value)) {
          elements.push(<DetailItem key={key} title={key} content={<DecodedPayloadTable payload={value} />} />)
        } else if (value && typeof value === 'object') {
            if (value !== null)
              elements.push(<DecodedPayloadObject key={key} title={key} payload={value} />)
            else
              elements.push(<DetailItem key={key} title={key} content={escapeEmptyDetail(value)} />)
        } else 
          elements.push(<DetailItem key={key} title={key} content={escapeEmptyDetail(value)} />)
      }
    return elements;
  }

  return (
    <>
      { decodedPayload &&
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{flexDirection: 'row-reverse'}}>
            <p className='detail-title'>Payload</p>
          </AccordionSummary>
          <AccordionDetails >
            {payloadElements()}
          </AccordionDetails>
        </Accordion>
      }
    </>
  );
};

export default DecodedPayload