import React from 'react';

const UserStateContext = React.createContext({
  workflowSelected: '',
  payloadSelected: '',
  tabActive: 0,
});
const UserDispatchContext = React.createContext(React.Dispatch);

function userReducer(state, action) {
  switch (action.type) {
    case 'SET_TAB_ACTIVE':
      return { ...state, tabActive: action.tabActive };
    case 'SET_WORKFLOW_SELECTED':
      return { ...state, workflowSelected: action.workflowSelected };
    case 'SET_PAYLOAD_SELECTED':
      return { ...state, payloadSelected: action.payloadSelected };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const UserProvider = ({ children }) => {
  let initialUserState = {
    tabActive: 0,
    workflowSelected: '',
    payloadSelected: '',
  };

  let [state, dispatch] = React.useReducer(userReducer, initialUserState);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

function useUserState() {
  let context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  let context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

// ###########################################################

async function tabActive(dispatch, tabActive) {
  dispatch({ type: 'SET_TAB_ACTIVE', tabActive });
}

async function workflowSelected(dispatch, workflowSelected) {
  dispatch({ type: 'SET_WORKFLOW_SELECTED', workflowSelected });
}

async function payloadSelected(dispatch, payloadSelected) {
  dispatch({ type: 'SET_PAYLOAD_SELECTED', payloadSelected });
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  tabActive,
  workflowSelected,
  payloadSelected,
};
