// var beURL = 'http://localhost:8080';
var beURL = 'https://pos-backend.dev.fiuturx.com';
// var beURL = 'https://pos-backend.qa.fiuturx.com';
var auth0_domain = 'https://xpansiv-dev.us.auth0.com';
var auth0_clientid = 'PZdtAPhS9arC4WO8szwQ9nsGHUci3Cdr';

if (process.env.REACT_APP_BE_URL && process.env.REACT_APP_BE_URL!==undefined) {
    beURL = `${process.env.REACT_APP_BE_URL}`;
}
if (process.env.REACT_APP_AUTH0_DOMAIN && process.env.REACT_APP_AUTH0_DOMAIN!==undefined) {
    auth0_domain = `${process.env.REACT_APP_AUTH0_DOMAIN}`;
}
if (process.env.REACT_APP_AUTH0_CLIENTID && process.env.REACT_APP_AUTH0_CLIENTID!==undefined) {
    auth0_clientid = `${process.env.REACT_APP_AUTH0_CLIENTID}`;
}

export { beURL, auth0_domain, auth0_clientid };