import React, { useState} from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';

function DetailItem(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  
  const openTempTooltip = () => {
    setTooltipOpen(true);
    setTimeout(() => {
        setTooltipOpen(false);
      }, 3000);
  }
    return (
        <div className='detail-item'>
            <div className='detail-title'>
                <p>{props.title}</p>
            </div>
            { props.link !== undefined ?
                <div className='detail-text'>
                    <span className='text-link' onClick={() => window.open(props.link, '_blank')}>{props.content}</span><OpenInNewIcon className='detail-text-icon' />
                </div>
                : props.copy !== undefined && props.content !== '-' ?
                <div className='detail-text'>
                    <span>{props.content}</span>
                    <Tooltip
                        placement={'right'}
                        arrow
                        open={tooltipOpen}
                        title={<span>Content was copied!</span>}
                    >
                        <ContentCopyIcon 
                            className='detail-text-icon copy-icon' 
                            onClick={() => {navigator.clipboard.writeText(props.copy); openTempTooltip();}}
                        />
                    </Tooltip>
                </div>
                : props.execFunction !== undefined &&  props.content !== '-' ?
                    <div className='detail-text'>
                        <span className='text-link' onClick={props.execFunction}>{props.content}</span>
                    </div>
                :
                <div className='detail-text'>
                    <span>{props.content}</span>
                </div>
            }
        </div>
  );
}

export default DetailItem;