import { readableStepName, escapeEmptyDetail } from '../../utils/utils';
import DetailItem from '../../components/DetailItem/DetailItem';

export const ContextualizationRequestStepModel = (step) => {
  return (
    <>
      <p className='details-title'>
        <b>
          { readableStepName(step['@type']) }
        </b>
      </p>
      <DetailItem title='Title' content={escapeEmptyDetail(step.title)} />
      <DetailItem title='Description' content={escapeEmptyDetail(step.description)} />
      <DetailItem title='Receiving Party Id' content={escapeEmptyDetail(step.receivingPartyId)} copy={step.receivingPartyId} />
      <DetailItem title='Receiving Party Name' content={escapeEmptyDetail(step.receivingPartyName)} />
      <DetailItem title='Requesting Party Id' content={escapeEmptyDetail(step.requestingPartyId)} copy={step.requestingPartyId} />
      <DetailItem title='Requesting Party Name' content={escapeEmptyDetail(step.requestingPartyName)} />
      <DetailItem title='Process Name' content={escapeEmptyDetail(step.processName)} />
      <DetailItem title='Process Version' content={escapeEmptyDetail(step.processVersion)} />
    </>
  );
};
