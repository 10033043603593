import React, { useState, createRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { TextField, Grid } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';

import ErrorMessage from '../../components/Error/Error';
import { addGeneralManualStep, addNotableEvent, getJsonSchemaFromUrl } from '../../api/api'
import { escapeEmptyDetail } from '../../utils/utils';
import { validationSchema } from '../../constants/contextualizationManualStep';
import { validationSchema as notableEventValidationSchema } from '../../constants/notableEventManualStep';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import DecodedPayloadEditableTable from '../../components/DecodedPayload/DecodedPayloadEditableTable';
import DetailItem from '../../components/DetailItem/DetailItem';

const uiSchema = require('../../constants/uiSchema');

const ContextualizationManualStep = (props) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [payload, setPayload] = useState(props.decodedPayload);
  const [approval, setApproval] = useState();
  const [jsonSchema, setJsonSchema] = useState();
  const [attributesJsonSchema, setAttributesJsonSchema] = useState();
  const [attrError, setAttrError] = useState(false);

  const formRef = createRef();

  useEffect(() => {

    setLoading(true);

    if (props.workflowStep.isCustomForm) {
      getJsonSchemaFromUrl(props.workflowStep.customFieldsURI)
      .then((res) => {
        setJsonSchema(JSON.parse(JSON.stringify(res.data)));
      })
      .catch((err) => {
        setDefaultSchema();
      })
      .finally(() => {
        setLoading(false);
      })
    } else {
      setDefaultSchema();
      setLoading(false);
    }

    if(props.workflowStep.customAttributesURI && props.workflowStep.customAttributesURI !== '') {
      getJsonSchemaFromUrl(props.workflowStep.customAttributesURI)
      .then((res) => {
        setAttributesJsonSchema(JSON.parse(JSON.stringify(res.data)));
      })
      .catch((err) => {
        setDefaultAttributesSchema();
      })
      .finally(() => {
        setLoading(false);
      })
    } else {
      setDefaultAttributesSchema();
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setDefaultSchema = () => {
    if(props.workflowStep['@type'] === 'ContextualizationStep') {
      setJsonSchema(require('../../constants/contextualizationJsonSchema.json'));
    } else if (props.workflowStep['@type'] === 'IDGeneratorStep')
      setJsonSchema(require('../../constants/idGeneratorJsonSchema.json'));
  }

  const setDefaultAttributesSchema = () => {
    setAttributesJsonSchema(require('../../constants/attributesJsonSchema'));
  }

  const transformErrors = (errors) => {
    return errors.map((error) => {
      if (error.name === 'pattern') {
        error.message = 'must follow UUID pattern';
      }
      return error;
    });
  }

  const handleSubmit = (values, formikHelpers) => {

    if(((approval === 'true' && formRef.current.validateForm()) || approval === 'false')) {
      submitForm(values, formikHelpers);
    }
    formikHelpers.setSubmitting(false);
  }

  const submitForm = (values, formikHelpers) => {

    delete values.title;
    delete values.description;

    if(approval === 'true') {
      var encodedPayload;
      delete values.content;

      if(props.workflowStep['@type'] === "ContextualizationStep")
        delete values.programId;

      if (payload && payload.attributes !== undefined) {
        const auxAttributes = payload.attributes.map(({id, ...attrs}) => attrs)
        const auxPayload = {...payload, attributes: auxAttributes}
        encodedPayload = Buffer.from(JSON.stringify(auxPayload)).toString('base64');
      } else {
        encodedPayload = values.payload;
      }
      
      values = {
        ...values,
        payload: encodedPayload
      };

      addGeneralManualStep(props.payloadId, values)
        .then(() => {
          navigate(0);
        })
        .catch((err) => {
          setHasError(true);
          formikHelpers.setSubmitting(false);
        })
        .finally(() => {
        setLoading(false);
      });
    } else if(approval === 'false') {
      
      values = {...values, isError: true};

      delete values['@type'];
      delete values.order;
      delete values.isManual;
      delete values.serviceName;
      delete values.programId;
      delete values.issuedCertificate;
      delete values.startingValues;
      delete values.result;
      delete values.supportingInformation;
      
      addNotableEvent(props.payloadId, values)
        .then(() => {
          navigate(0);
        })
        .catch((err) => {
          if (err.response.data['error.key'] !== null) {
            formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
            formikHelpers.setSubmitting(false);
          } else if(err.response.data.fieldErrors !== null) {
            let errors = {};
            err.response.data.fieldErrors.forEach(el => {
              errors[el.field] = el.message;
            })
            formikHelpers.setErrors(errors);
            formikHelpers.setSubmitting(false);
          } else {
            setHasError(true)
            setErrorStatus(err.response.status)
            setErrorDetail(err.response.data.detail);
          }
        })
        .finally(() => {
        setLoading(false);
      });
    }
  }

  const onChangeAttributes = (newAttributes) => {
    setPayload({...payload, attributes: newAttributes})
  }

  return (
    <>
      {loading ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
        { hasError ? (
            <Modal
              open={props.open}
              onClose={props.onClose}
              className='formModal'
            >
            <Modal.Header>{props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)} Manual Step</Modal.Header>
              <Modal.Content className='formModal-content'>
                <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
              </Modal.Content>
              <Modal.Actions>
                  <Button onClick={props.onClose}>
                    Close
                  </Button>
                </Modal.Actions>
            </Modal>
          ) : (
            <Formik
              validateOnChange
                initialValues={
                  {
                    payload: props.payload64 ?? '',
                    '@type': props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)+'Event',
                    order: props.workflowStep.order ?? '',
                    isManual: props.workflowStep.isManual ?? '',
                    serviceName: undefined,
                    issuedCertificate: undefined,
                    programId: undefined,
                    startingValues: [{name: undefined, value: undefined}],
                    result: [{name: undefined, value: undefined}],
                    supportingInformation: [],
                    content: '',
                    title: props.workflowStep.title,
                    description: props.workflowStep.description
                  }
                }
                validationSchema={
                  approval === 'true' ?
                    validationSchema
                  :
                    notableEventValidationSchema}
                onSubmit={handleSubmit}
            >
              { formProps => ( 
                <form onSubmit={formProps.handleSubmit} noValidate >
                  <Modal
                      open={props.open}
                      onClose={props.onClose}
                      className='formModal'
                  >
                    <Modal.Header>{props.workflowStep['@type'].slice(0, props.workflowStep['@type'].length - 4)} Manual Step</Modal.Header>
                    <Modal.Content className='formModal-content' >
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <DetailItem title='Title' content={escapeEmptyDetail(formProps.values.title)} />
                        </Grid>
                        <Grid item md={12}>
                          <DetailItem title='Description' content={escapeEmptyDetail(formProps.values.description)} />
                        </Grid>
                        { !payload &&
                              <Grid item md={12}>
                                <TextField
                                  required
                                  fullWidth={true}
                                  label='Payload'
                                  id='payload'
                                  name='payload'
                                  placeholder='Payload'
                                  value={formProps.values.payload}
                                  onChange={formProps.handleChange}
                                  error={formProps.touched.payload && Boolean(formProps.errors.payload)}
                                  helperText={formProps.touched.payload && formProps.errors.payload}
                                />
                              </Grid>
                            }
                            { payload &&
                              <Grid item md={12}>
                                <DecodedPayload decodedPayload={props.decodedPayload} />
                              </Grid>
                            }
                        <Grid item md={12}>
                          <ToggleButtonGroup
                            value={approval}
                            exclusive
                            onChange={(e) => setApproval(e.target.value)}
                            aria-label='action'
                          >
                            <ToggleButton value='true'>
                              Approve
                            </ToggleButton>
                            <ToggleButton value='false'>
                              Reject
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                        { approval === 'true' ?
                          <>
                            <Grid item md={12}>
                              <Form
                                ref={formRef}
                                schema={jsonSchema}
                                uiSchema={uiSchema}
                                validator={validator}
                                formData={formProps.values}
                                // liveValidate={true}
                                noHtml5Validate
                                showErrorList={false}
                                transformErrors={transformErrors}
                                onChange={({formData}) => formProps.setValues(formData)}
                                children={true}
                              />
                            </Grid>
                            { payload &&
                              <>
                              { payload.attributes !== undefined &&
                                <>
                                  <Grid item md={12}>
                                    <p>Attributes</p>
                                  </Grid>
                                  <Grid item md={12}>
                                    <DecodedPayloadEditableTable attributes={payload.attributes} attributesJsonSchema={attributesJsonSchema} onChangeAttributes={onChangeAttributes} setAttrError={setAttrError} />
                                  </Grid>
                                </>
                              }
                              </>
                            }
                          </>
                        : approval === 'false' ?
                          <Grid item md={12}>
                            <TextField
                                required
                                fullWidth={true}
                                label='Reason'
                                id='content'
                                name='content'
                                placeholder='Reason'
                                value={formProps.values.content}
                                onChange={formProps.handleChange}
                                error={formProps.touched.content && Boolean(formProps.errors.content)}
                                helperText={formProps.touched.content && formProps.errors.content}
                            />
                          </Grid>
                        :
                            <></>
                        }
                      </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button disabled={formProps.isSubmitting} onClick={props.onClose}>Cancel</Button>
                      <Button disabled={formProps.isSubmitting || approval === undefined || attrError === true} className='actionButton' type='submit' onClick={formProps.handleSubmit}>Submit</Button>
                    </Modal.Actions>
                  </Modal>
                </form>
              )} 
            </Formik>
          )}
        </>
      )}
    </>
  )
}

export default ContextualizationManualStep
