import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ErrorMessage from '../../../components/Error/Error';
import { useUserDispatch, tabActive } from '../../../context/UserContext';
import { listMasterCertificate, issueMasterCertificate } from '../../../api/api';

const validationSchema = yup.object().shape({
  externalPayloadId: yup
    .string()
    .required('External Payload Id is required')
    .matches(/(\S.*)/, 'External Payload Id should not be blank'),
});

const IssueMasterCertificate = (props) => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const [loading, setLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState();
  const [masterCertificate, setMasterCertificate] = useState();

  useEffect(() => {
    if (window.location.pathname === `/master-certificates`) {
      tabActive(userDispatch, 0)
    }
    window.localStorage.setItem('main_tab', 'certificates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLoading(true);

    let id = props.masterCertificateId;
    setLoading(true);

    if (id) {
      // getting workflow data to display steps details
      listMasterCertificate(id)
        .then((res) => {
          setMasterCertificate(res.data);
          setFormData({
            masterCertificateId: id,
            certificateName: res.data.certificateName,
            externalPayloadId: '',
            activationDate: res.data.activationDate ? res.data.activationDate : '',
            expirationDate: res.data.expirationDate ? res.data.expirationDate : ''
          });
        })
        .catch((err) => {
          setHasError(true);
          setErrorStatus(err.response.status);
          setErrorDetail(err.response.data.detail);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setHasError(true);
      setErrorDetail('Master Certificate Id not found');
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (values, formikHelpers) => {
  
    values = {...values,
      activationDate: values.activationDate ? values.activationDate : null,
      expirationDate: values.expirationDate ? values.expirationDate : null
    }
    delete values.certificateName;

    issueMasterCertificate(values)
      .then((res) => {
        tabActive(userDispatch, 3);
        navigate(`/issued-certificates/`);
      })
      .catch((err) => {
        if (err.response.data['error.key'] !== null) {
          formikHelpers.setErrors({[err.response.data['error.key']]: err.response.data.detail});
          formikHelpers.setSubmitting(false);
        } else if(err.response.data.fieldErrors !== null) {
          let errors = {};
          err.response.data.fieldErrors.forEach(el => {
            errors[el.field] = el.message;
          })
          formikHelpers.setErrors(errors);
          formikHelpers.setSubmitting(false);
        } else {
          setHasError(true)
          setErrorStatus(err.response.status)
          setErrorDetail(err.response.data.detail);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {loading || !formData || !masterCertificate ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          { hasError ? (
              <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
            ) : (
              <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                { formProps => ( 
                  <form onSubmit={formProps.handleSubmit} noValidate >
                    <Modal
                      open={props.open}
                      onClose={props.onClose}
                      className='formModal'
                    >
                      <Modal.Header>Issue Master Certificate</Modal.Header>
                      <Modal.Content>
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <TextField 
                              fullWidth={true}
                              disabled
                              label='Master Certificate Id'
                              id='masterCertificateId'
                              name='masterCertificateId'
                              placeholder='Master Certificate Id'
                              value={formProps.values.masterCertificateId ?? ''}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField 
                              fullWidth={true}
                              disabled
                              label='Certificate Name'
                              id='certificateName'
                              name='certificateName'
                              placeholder='Certificate Name'
                              value={formProps.values.certificateName ?? ''}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              required
                              fullWidth={true}
                              label='External Payload Id'
                              id='externalPayloadId'
                              name='externalPayloadId'
                              placeholder='External Payload Id'
                              value={formProps.values.externalPayloadId ?? ''}
                              onChange={formProps.handleChange}
                              error={formProps.touched.externalPayloadId && Boolean(formProps.errors.externalPayloadId)}
                              helperText={formProps.touched.externalPayloadId && formProps.errors.externalPayloadId}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                format='MM-DD-YYYY'
                                id='activationDate'
                                name='activationDate'
                                label='Activation Date'
                                value={dayjs(dayjs(formProps.values.activationDate).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(formProps.values.activationDate) : null}
                                onChange={(e) => formProps.handleChange({target: {value: dayjs(e).format('YYYY-MM-DD')+'T00:00:00Z', name: 'activationDate'}})}
                                error={Boolean(formProps.errors.activationDate)}
                                helperText={formProps.errors.activationDate}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                format='MM-DD-YYYY'
                                id='expirationDate'
                                name='expirationDate'
                                label='Expiration Date'
                                value={dayjs(dayjs(formProps.values.expirationDate).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(formProps.values.expirationDate) : null}
                                onChange={(e) => formProps.handleChange({target: {value: dayjs(e).format('YYYY-MM-DD')+'T00:00:00Z', name: 'expirationDate'}})}
                                error={Boolean(formProps.errors.expirationDate)}
                                helperText={formProps.errors.expirationDate}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button disabled={formProps.isSubmitting} onClick={props.onClose}>Cancel</Button>
                        <Button disabled={formProps.isSubmitting} type='submit' className='actionButton' onClick={formProps.handleSubmit}>Issue</Button>
                      </Modal.Actions>
                    </Modal>
                  </form>
                  )
                } 
              </Formik>
            )
          }
        </>
      )}
    </>
  )
}

export default IssueMasterCertificate
