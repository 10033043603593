import React, { useState, useEffect } from 'react';
import { Button, Loader, Modal } from 'semantic-ui-react';

import { useUserDispatch, tabActive } from '../../../context/UserContext';
import { deleteProductionEntity } from '../../../api/api';

const DeleteProductionEntity = (props) => {
  const userDispatch = useUserDispatch();

  const [loading, setLoading] = useState(false);
  const [, setErrorStatus] = useState();
  const [, setErrorDetail] = useState();
  const [, setHasError] = useState(false);

  useEffect(() => {
    if (window.location.pathname === `/production-entities`) {
      tabActive(userDispatch, 2);
    }
    window.localStorage.setItem('main_tab', 'certificates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteProdEntity = () => {
    setLoading(true);

    deleteProductionEntity(props.productionEntityId)
      .then((res) => {
        props.onCloseSuccessfully();
      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className='payload'>
      { loading ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <Modal
            open={props.open}
            onClose={props.onClose}
            >
            <Modal.Header>Delete Production Entity</Modal.Header>
            <Modal.Content>
                Are you sure you want to delete Production Entity: '{props.productionEntityId}' ?
            </Modal.Content>
            <Modal.Actions>
                 <Button onClick={props.onClose}>
                    Close
                  </Button>
                <Button negative onClick={deleteProdEntity}>
                Delete
                </Button>
            </Modal.Actions>
            </Modal>
      )}
    </div>
  )
}

export default DeleteProductionEntity
