import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';

import ErrorMessage from '../../../components/Error/Error';
import Arrow from '../../../images/Arrow.svg';
import { useUserDispatch, tabActive } from '../../../context/UserContext';
import { listMasterCertificate, listProductionEntities } from '../../../api/api';
import { dateFormatter, escapeEmptyDetail } from '../../../utils/utils';
import DetailItem from '../../../components/DetailItem/DetailItem';
import AddMasterCertificate from '../Add/AddMasterCertificate';
import DeleteMasterCertificate from '../Delete/DeleteMasterCertificate';

const MasterCertificatesDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userDispatch = useUserDispatch();

  const [loading, setLoading] = useState(true);
  const [masterCertificate, setMasterCertificate] = useState();
  const [productionEntities, setProductionEntities] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (window.location.pathname === `/master-certificates/${params.id}`) {
      tabActive(userDispatch, 0)
    }
    window.localStorage.setItem('main_tab', 'certificates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let id = params.id;
    setLoading(true);

    if (id) {
      getMasterCertificate(id);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMasterCertificate = (id) => {
    listMasterCertificate(id)
      .then((res) => {
        setMasterCertificate(res.data);
      })
      .then(
        listProductionEntities('10000')
        .then((res) => {
          buildProductionEntityList(res.data.content);
        })
      )
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const buildProductionEntityList = (prods) => {
    const productionEntities = prods.map((el, index) => {
      return { key: index, text: el.name, value: el.productionEntityId};
    })
    setProductionEntities(productionEntities);
  }

  return (
    <div className='payload'>
      {loading || (!masterCertificate && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <>
             <section className='search-result'>
                <p
                  className='link'
                  onClick={() => {
                    navigate('/master-certificates')
                  }}
                >
                  Master Certificates
                </p>
                <p>
                  <img src={Arrow} alt='arrow'></img>
                </p>
                <p>{masterCertificate.certificateName}</p>
              </section>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  display: 'block',
                }}
              >
                <Grid container>
                  <Grid item md={10}>
                    <p className='details-title'>Master Certificate Details: {masterCertificate.certificateName}</p>
                  </Grid>
                  <Grid item md={2}>
                    <section className='export-btn-wrapper'>
                      <Tooltip placement={'top'} arrow title='Edit Master Certificate'>
                        <button className='sm-btn' onClick={(e) => {e.stopPropagation(); setEditModalOpen(true)}}> <EditIcon /> </button>
                      </Tooltip>
                      <Tooltip placement={'top'} arrow title='Delete Master Certificate'>
                        <button className='sm-btn danger' onClick={(e) => {e.stopPropagation(); setDeleteModalOpen(true)}} > <DeleteIcon /> </button>
                      </Tooltip>
                    </section>
                  </Grid>
                </Grid>
                <Box
                sx={{
                  width: '100%',
                  display: 'grid',
                  justifyContent: 'initial'
                }}
                >
                  <DetailItem title='Id' content={escapeEmptyDetail(masterCertificate.masterCertificateId)} copy={masterCertificate.masterCertificateId} />
                  <DetailItem title='Certifying Body Party' content={escapeEmptyDetail(masterCertificate.certifyingBodyPartyName)} />
                  <DetailItem title='Certificate Holder Party' content={escapeEmptyDetail(masterCertificate.certificateHolderPartyName)} />
                  <DetailItem title='Certificate Type' content={escapeEmptyDetail(masterCertificate.certificateType)} />
                  <DetailItem title='Certificate Rating' content={escapeEmptyDetail(masterCertificate.certificateRating)} />
                  <DetailItem title='Certificate Version' content={escapeEmptyDetail(masterCertificate.certificateVersion)} />
                  <DetailItem title='Certificate Name' content={escapeEmptyDetail(masterCertificate.certificateName)} />
                  <DetailItem title='External Certificate Id' content={escapeEmptyDetail(masterCertificate.externalCertificateId)} />
                  <DetailItem
                    title='Production Entities'
                    content={masterCertificate.productionEntities?.length > 0 ? 
                              masterCertificate.productionEntities?.map((el, index) => {
                                return Boolean(productionEntities?.find( item => item.value === el)) &&
                                  <span key={index}>{productionEntities ? productionEntities.find( item => item.value === el)?.text : ''}{index+1 !== (masterCertificate.productionEntities).length && ', '}</span>
                              })
                              :
                              '-'
                            }
                  />
                  <DetailItem title='Acessor Name' content={escapeEmptyDetail(masterCertificate.assessorName)} />
                  <DetailItem title='Facility Id' content={escapeEmptyDetail(masterCertificate.facilityId)} />
                  <DetailItem title='Status' content={escapeEmptyDetail(masterCertificate.status)} />
                  <DetailItem title='Activation Date' content={dateFormatter(masterCertificate.activationDate)} />
                  <DetailItem title='Expiration Date' content={dateFormatter(masterCertificate.expirationDate)} />
                  <DetailItem title='External Certificate URL' content={escapeEmptyDetail(masterCertificate.externalCertificateUrl)} />
                  <DetailItem title='Data' content={escapeEmptyDetail(masterCertificate.data)} />
                  <DetailItem
                    title='Bounding Box'
                    content={masterCertificate.boundingBox?.length > 0 ?
                      masterCertificate.boundingBox?.map((el, index) => {
                        return <span key={index}>{`'${el}'`}{index+1 !== (masterCertificate.boundingBox).length && '; '}</span>
                      }) 
                      :
                      '-'
                    }
                  />
                </Box>
              </Box>
              { editModalOpen &&
                <AddMasterCertificate 
                  open={editModalOpen}
                  onClose={() => setEditModalOpen(false)}
                  onCloseSuccessfully={() => {setEditModalOpen(false); getMasterCertificate(masterCertificate.masterCertificateId);}}
                  masterCertificateId={masterCertificate.masterCertificateId}
                />
              }
              { deleteModalOpen &&
                <DeleteMasterCertificate 
                  open={deleteModalOpen}
                  onClose={() => setDeleteModalOpen(false)}
                  onCloseSuccessfully={() => {setDeleteModalOpen(false); navigate('/master-certificates');}}
                  masterCertificateId={masterCertificate.masterCertificateId}
                />
              }
            </>
          )}
        </>
      )}
    </div>
  )
}

export default MasterCertificatesDetails

