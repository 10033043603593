import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  payload: yup
    .string()
    .required('Payload is required')
    .matches(/(\S.*)/, 'Payload should not be blank'),
  content: yup
    .string()
    .required('Reason is required')
    .matches(/(\S.*)/, 'Reason should not be blank'),
});
