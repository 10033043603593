import React, { useState, useEffect } from 'react';
import StripedTable from '../Table/StripedTable.jsx';

import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { escapeEmptyDetail } from '../../utils/utils';

const DecodedPayloadTable = (props) => {

  const decodedPayload = props.payload;
  
  const itemsPerPage = 5;
  const noOfPages = Math.ceil(decodedPayload?.length / itemsPerPage);
  const [page, setPage] = useState(1);
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    let headings = [];
    if (decodedPayload && decodedPayload[0] !== undefined) {
      for (const [key] of Object.entries(decodedPayload[0])) {
        headings.push(key);
      }

      headings.sort();
      setHeadings(headings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getElements = (payload) => {
    let elements = [];
    headings.forEach( el => {
      elements.push(<p>{escapeEmptyDetail(payload[`${el}`])}</p>)
    })
    return elements;
  }

  return (
    <>
      <div style={{paddingTop: '10px'}} >
        <StripedTable
          headings={headings}
          rows={decodedPayload
          ?.slice(
            (page - 1) * itemsPerPage,
            page * itemsPerPage
          )
          .map((payload) => {
            if (decodedPayload?.length) {
              return {
                elements: getElements(payload)
              }
            } else {
              return {
                elements: [],
              }
            }
          })}
        />
        { noOfPages > 1 &&
          <Box
            component='div'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3rem 0'
            }}
            >
            <Pagination
              count={noOfPages}
              page={page}
              onChange={(e, page) => setPage(page)}
              defaultPage={1}
              color='primary'
              size='small'
            />
          </Box>
        }
      </div>
    </>
  );
};

export default DecodedPayloadTable