import { readableStepName, escapeEmptyDetail } from '../../utils/utils';
import DetailItem from '../../components/DetailItem/DetailItem';

export const AssetRegistrationStepModel = (step) => {
  return (
    <>
      <p className='details-title'>
        <b>
          { readableStepName(step['@type']) }
        </b>
      </p>
      <DetailItem title='Title' content={escapeEmptyDetail(step.title)} />
      <DetailItem title='Description' content={escapeEmptyDetail(step.description)} />
      <DetailItem title='Old Custodian Party Id' content={escapeEmptyDetail(step.oldCustodianPartyId)} copy={step.oldCustodianPartyId} />
      <DetailItem title='Old Custodian Party Name' content={escapeEmptyDetail(step.oldCustodianPartyName)} />
      <DetailItem title='Registry Account Id' content={escapeEmptyDetail(step.registryAccountId)} copy={step.registryAccountId} />
      <DetailItem title='Registry Account Name' content={escapeEmptyDetail(step.registryAccountname)} />
      <DetailItem title='Registry' content={escapeEmptyDetail(step.registry)} />
      <DetailItem title='Registry Provider' content={escapeEmptyDetail(step.registryProvider)} />
      <DetailItem title='Registry Environment' content={escapeEmptyDetail(step.registryEnvironment)} />
      <DetailItem title='Registry API Version' content={escapeEmptyDetail(step.registryAPIVersion)} />
    </>
  );
};
