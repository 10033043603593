import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Loader, Table, Header } from 'semantic-ui-react';

import PaginationControls from './PaginationControls';

const StripedTable = (props) => {
  // const clickIcon =
  //   props.clickableIcon ||
  //   (props.rowsClickable && !props.clickableIcon ? (
  //     <svg className='icon arrow-left-icon' viewBox='0 0 13 12'>
  //       <path
  //         className='fill-blue'
  //         fill='#0380ff'
  //         d='M6.3,2.3L9,5H0.8C0.4,5,0,5.3,0,5.8s0.3,0.8,0.8,0.8H9L6.3,9.3C6.2,9.4,6.1,9.6,6.1,9.8s0.1,0.4,0.2,0.5
  //               c0.3,0.3,0.8,0.3,1.1,0l4-4c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.4,0-0.5c0-0.1-0.1-0.2-0.2-0.2l-4-4C7,1,6.5,1,6.2,1.3
  //               C6,1.5,6,2,6.3,2.3'
  //       />
  //     </svg>
  //   ) : undefined);

  const ROWS_PER_PAGE = undefined || 0;

  const totalPages = ROWS_PER_PAGE
    ? Math.ceil(props.rows.length / ROWS_PER_PAGE)
    : 0;

  const [activePage, setActivePage] = useState(1);
  const [activePageRows, setActivePageRows] = useState([]);

  useEffect(() => {
    if (ROWS_PER_PAGE) {
      setActivePageRows(
        props.rows.slice(
          (activePage - 1) * ROWS_PER_PAGE,
          activePage * ROWS_PER_PAGE
        )
      );
    } else {
      setActivePageRows(props.rows);
    }
  }, [activePage, props.rows, ROWS_PER_PAGE]);

  // TODO: In body of empty table, provide a link to whatever needs to be done to make the table un-empty
  if (activePageRows.length === 0) {
    return (
      <div className='striped-table'>
        {!!props.title && <Header as='h2'>{props.title}</Header>}
        <div className='empty-table'>
          <p className='p2'>No results found...</p>
        </div>
      </div>
    );
  }

  if (props.loading) {
    return (
      <div className='striped-table'>
        {!!props.title && <Header as='h2'>{props.title}</Header>}
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      </div>
    );
  }

  return (
    <div className='striped-table'>
      {!!props.title && <Header as='h2'>{props.title}</Header>}

      <Table unstackable>
        <Table.Header>
          <Table.Row>
            {props.headings.map((heading, index) => (
              <Table.HeaderCell key={index} textAlign={'left'}>
                {heading}
              </Table.HeaderCell>
            ))}
            {/* {!!clickIcon && <Table.HeaderCell width={1}></Table.HeaderCell>} */}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {activePageRows.map((row, i) => (
            <Table.Row
              key={i}
              className={classNames({ clickable: props.rowsClickable })}
              onClick={row?.onClick}
            >
              {row?.elements.map((item, j) => (
                <Table.Cell key={j} textAlign={'left'}>
                  <b className='label'>{props.headings[j]}: </b>
                  {item}
                </Table.Cell>
              ))}
              {/* {!!clickIcon && (
                <Table.Cell
                  key={row?.elements.length + 1}
                  textAlign={'right'}
                  width={1}
                  className='action-icons-group'
                >
                  {clickIcon}
                </Table.Cell>
              )} */}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {totalPages > 1 && (
        <PaginationControls
          totalPages={totalPages}
          onPageChange={(num) => setActivePage(num)}
        />
      )}
    </div>
  );
};

export default StripedTable;
