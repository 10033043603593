import { Grid } from 'semantic-ui-react';
import Box from '@mui/material/Box';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import { readableStepName, escapeEmptyDetail } from '../../utils/utils';
import DetailItem from '../../components/DetailItem/DetailItem';

export const DataCustodyReceivedEventModel = (event, decodedPayload) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
        }}>
        <p className='details-title'>
          <b>
            {
              readableStepName(event['@type'])
            }
          </b>
        </p>
        <DetailItem title='Title' content={escapeEmptyDetail(event.title)} />
        <DetailItem title='Description' content={escapeEmptyDetail(event.description)} />
        <DetailItem title='Old Custodian Party Id' content={escapeEmptyDetail(event.oldCustodianPartyId)} copy={event.oldCustodianPartyId} />
        <DetailItem title='Old Custodian Party Name' content={escapeEmptyDetail(event.oldCustodianPartyName)} />
        <DetailItem title='New Custodian Party Id' content={escapeEmptyDetail(event.newCustodianPartyId)} copy={event.newCustodianPartyId} />
        <DetailItem title='New Custodian Party Name' content={escapeEmptyDetail(event.newCustodianPartyName)} />
        <DetailItem title='Schema' content={escapeEmptyDetail(event.schema)} />
        <DetailItem title='Created at' content={event.createdAt.split('T', 1) + ' ' + event.createdAt.split('T', 2)[1].split('.', 1)+' (GMT)'} />
      </Box>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
        }}>
          { decodedPayload ?
            <DecodedPayload decodedPayload={decodedPayload} />
            :
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <p className='detail-title'>Payload </p>
                <p className='detail-text'>{escapeEmptyDetail(event.payload)}</p>
              </Grid.Column>
            </Grid>
          }
      </Box>
    </>
  );
};
