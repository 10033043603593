import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { Grid, InputAdornment } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForward from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField, MenuItem } from '@mui/material';
import { ToggleButtonGroup, ToggleButton, Pagination } from '@mui/material/';

import StripedTable from '../../components/Table/StripedTable.jsx';
import ErrorMessage from '../../components/Error/Error';
import { listMasterCertificates } from '../../api/api';
import { useUserDispatch, tabActive } from '../../context/UserContext';
import { dateFormatter, escapeEmptyDetail, useDidMountEffect } from '../../utils/utils.jsx';
import { masterCertificateTypes } from '../../constants/masterCertificates.jsx';
import AddMasterCertificate from './Add/AddMasterCertificate.jsx';
import IssueMasterCertificate from './Issue/IssueMasterCertificate.jsx';
import DeleteMasterCertificate from './Delete/DeleteMasterCertificate.jsx';

const MasterCertificates = () => {
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();

  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(true);
  const [, setInitialRows] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState();
  const [currentParty, setCurrentParty] = useState();
  const facilityIdRef = useRef(null);
  const certificateHolderRef = useRef(null);

  // table filter
  const [searchedType, setSearchedType] = useState('');
  const [searchActivationDate, setSearchActivationDate] = useState(null);
  const [activationDateError, setActivationDateError] = useState(null);
  const [searchExpirationDate, setSearchExpirationDate] = useState(null);
  const [expirationDateError, setExpirationDateError] = useState(null);
  const [searchFacilityId, setSearchFacilityId] = useState('');
  const [searchCertificateHolderName, setSearchCertificateHolderName] = useState('');
  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    if (window.location.pathname === '/master-certificates') {
      tabActive(userDispatch, 0);
    }
    window.localStorage.setItem('main_tab', 'certificates');

    var current_party = JSON.parse(localStorage.getItem('current_party'));
    setCurrentParty(current_party);

    getMasterCertificates();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchedType, searchActivationDate, searchExpirationDate]);

  useDidMountEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(searchFacilityId !== '' || searchCertificateHolderName !== '') {
        getMasterCertificates();
      }
    }, 500)
  
    return () => clearTimeout(delayDebounceFn)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFacilityId, searchCertificateHolderName]);


  // typeArr.push({
    //           key: index,
    //           value: String(a.certificateType),
    //           text: a.certificateType,
    //         });

    

  const getMasterCertificates = () => {

    setLoading(true);
    
    listMasterCertificates(
      itemsPerPage,
      page - 1,
      searchedType,
      searchActivationDate,
      searchExpirationDate,
      searchFacilityId,
      searchCertificateHolderName
    )
      .then((result) => {
        setInitialRows(result.data.content);
        setRows(result.data.content);
        setNoOfPages(result.data.totalPages);
      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useDidMountEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      setLoading(true);
      getMasterCertificates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);

  const handleItemsPerPage = (event, newAmount) => {
    setItemsPerPage(newAmount);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {loading || (rows?.length === undefined && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <div className='Home'>
              <section className='filter-section'>
                <TextField
                  select
                  label='Type'
                  placeholder='Type'
                  value={searchedType}
                  onChange={(valueSelected) => {
                    setSearchedType(valueSelected.target.value);
                  }}
                  >
                    <MenuItem key={'all'} value=''>All</MenuItem>
                    {masterCertificateTypes.map(el =>
                      <MenuItem key={el.key} value={el.value}>{el.text}</MenuItem>
                    )}
                </TextField>
                <div className='datepicker-wrapper'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format='MM-DD-YYYY'
                      label='Activation Date'
                      value={dayjs(dayjs(searchActivationDate).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(searchActivationDate) : null}
                      onChange={(newValue) => {
                        setTimeout(function(){
                          if (newValue === '' || newValue === null) {
                            setSearchActivationDate(null);
                            setActivationDateError(null);
                          } else if (!dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()){
                            setActivationDateError('Invalid date');
                          } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).year() < 1900){
                            setActivationDateError('Activation date should be greater than 1899');
                          } else if(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                            setSearchActivationDate(dayjs(newValue).format('YYYY-MM-DD'));
                            setActivationDateError(null);
                          }
                        }, 500);
                      }}
                      slotProps={{
                        actionBar: { actions: ["clear"], position: "left" }
                      }}
                    />
                  </LocalizationProvider>
                  <p>
                    {activationDateError}
                  </p>
                </div>
                <div className='datepicker-wrapper'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format='MM-DD-YYYY'
                      label='Expiration Date'
                      value={dayjs(dayjs(searchExpirationDate).format('MM-DD-YYYY'), 'MM-DD-YYYY', true).isValid() ? dayjs(searchExpirationDate) : null}
                      onChange={(newValue) => {
                        setTimeout(function(){
                          if (newValue === '' || newValue === null) {
                            setSearchExpirationDate(null);
                            setExpirationDateError(null);
                          } else if (!(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid())){
                            setExpirationDateError('Invalid date');
                          } else if (dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid() && dayjs(newValue).year() < 1900){
                            setExpirationDateError('Expiration Date should be greater than 1899');
                          } else if(dayjs(dayjs(newValue).format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid()) {
                            setSearchExpirationDate(dayjs(newValue).format('YYYY-MM-DD'));
                            setExpirationDateError(null);
                          }
                        }, 500);
                      }}
                      slotProps={{
                        actionBar: { actions: ["clear"], position: "left" }
                      }}
                    />
                  </LocalizationProvider>
                  <p>
                    {expirationDateError}
                  </p>
                </div>
                <TextField
                  type='search'
                  label='Facility Id'
                  value={searchFacilityId}
                  onChange={(searchVal) => {
                    setSearchFacilityId(searchVal.target.value);
                  }
                  }
                  inputRef={facilityIdRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type='search'
                  label='Certificate Holder Name'
                  value={searchCertificateHolderName}
                  onChange={(searchVal) => {
                    setSearchCertificateHolderName(searchVal.target.value);
                  }
                  }
                  inputRef={certificateHolderRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </section>
              <Grid container sx={{paddingRight: 2}}>
                <Grid item md={12}>
                  <section className='table-btn-wrapper'>
                    <Tooltip placement={'top'} arrow title='Add Master Certificate'>
                      <button onClick={() => setAddModalOpen(true)}> Add New</button>
                    </Tooltip>
                  </section>
                </Grid>
              </Grid>
              <section className='content'>
                {rows?.length !== undefined && !loading ? (
                  <>
                    <StripedTable
                      aria-label='simple table'
                      rowsClickable
                      headings={[
                        'Id',
                        'Name',
                        'Type',
                        'Activation Date',
                        'Expiration Date',
                        'Facility Id',
                        'Certificate Holder Name',
                        ''
                      ]}
                      rows={rows?.map((data, index) => {
                        if (data) {
                          return {
                            elements: [
                              <p>{escapeEmptyDetail(data.masterCertificateId)}</p>,
                              <p>{escapeEmptyDetail(data.certificateName)}</p>,
                              <p>{escapeEmptyDetail(data.certificateType )}</p>,
                              <p>{dateFormatter(data.activationDate)}</p>,
                              <p>{dateFormatter(data.expirationDate)}</p>,
                              <p>{escapeEmptyDetail(data.facilityId )}</p>,
                              <p>{escapeEmptyDetail(data.certificateHolderPartyName)}</p>,
                              <>
                                { (currentParty.id === data.certifyingBodyPartyId || currentParty.roles.includes('RegistryOperator')) &&
                                  <section className='export-btn-wrapper'>
                                    <Tooltip placement={'top'} arrow title='Issue Master Certificate'>
                                      <button className='sm-btn' onClick={(e) => {e.stopPropagation(); setSelectedCertificate(data.masterCertificateId); setIssueModalOpen(true)}}> <ArrowForward /> </button>
                                    </Tooltip>
                                    <Tooltip placement={'top'} arrow title='Edit Master Certificate'>
                                      <button className='sm-btn' onClick={(e) => {e.stopPropagation(); setSelectedCertificate(data.masterCertificateId); setEditModalOpen(true)}}> <EditIcon /> </button>
                                    </Tooltip>
                                    <Tooltip placement={'top'} arrow title='Delete Master Certificate'>
                                      <button className='sm-btn danger' onClick={(e) => {e.stopPropagation(); setSelectedCertificate(data.masterCertificateId); setDeleteModalOpen(true)}} > <DeleteIcon /> </button>
                                    </Tooltip>
                                  </section>
                                }
                              </>
                            ],
                            onClick: () => {
                              navigate(`/master-certificates/${data.masterCertificateId}`);
                            },
                          };
                        } else {
                          return {
                            elements: [],
                          };
                        }
                      })}
                    ></StripedTable>
                    <Grid container spacing={2}>
                      <Grid item md={4}/>
                      <Grid item md={4}>
                        <Box
                          component='div'
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '3rem 0'
                          }}
                          >
                          <Pagination
                            count={noOfPages}
                            page={page}
                            onChange={handleChangePage}
                            defaultPage={0}
                            color='primary'
                            size='small'
                          />
                        </Box>
                      </Grid>
                      {rows?.length > 0 &&
                        <Grid item md={4} p={2}>
                          <Box
                            component='div'
                            sx={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'center',
                              padding: '3rem 0'
                            }}
                            className='perpage'>
                            <Tooltip
                              placement={'right'}
                              arrow
                              title={<span>View per page</span>}
                            >
                              <ToggleButtonGroup
                                value={itemsPerPage}
                                exclusive
                                onChange={handleItemsPerPage}
                                aria-label='text alignment'
                                size='small'
                              >
                                <ToggleButton value={10} aria-label='left aligned'>
                                  10
                                </ToggleButton>
                                <ToggleButton value={50} aria-label='centered'>
                                  50
                                </ToggleButton>
                                <ToggleButton value={100} aria-label='justified'>
                                  100
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Tooltip>
                          </Box>
                        </Grid>
                      }
                    </Grid>
                    { issueModalOpen &&
                      <IssueMasterCertificate
                        open={issueModalOpen}
                        onClose={() => setIssueModalOpen(false)}
                        onCloseSuccessfully={() => {setIssueModalOpen(false); getMasterCertificates();}}
                        masterCertificateId={selectedCertificate}
                      />
                    }
                    { addModalOpen &&
                      <AddMasterCertificate
                        open={addModalOpen}
                        onClose={() => setAddModalOpen(false)}
                        onCloseSuccessfully={() => {setAddModalOpen(false); getMasterCertificates();}}
                      />
                    }
                    { editModalOpen &&
                      <AddMasterCertificate 
                        open={editModalOpen}
                        onClose={() => setEditModalOpen(false)}
                        onCloseSuccessfully={() => {setEditModalOpen(false); getMasterCertificates();}}
                        masterCertificateId={selectedCertificate}
                      />
                    }
                    { deleteModalOpen &&
                      <DeleteMasterCertificate 
                        open={deleteModalOpen}
                        onClose={() => setDeleteModalOpen(false)}
                        onCloseSuccessfully={() => {setDeleteModalOpen(false); getMasterCertificates();}}
                        productionEntityId={selectedCertificate}
                      />
                    }
                  </>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      <div className='empty-table'>
                        <Loader active indeterminate size='small' />
                      </div>
                    ) : (
                      <StripedTable rows={[]}></StripedTable>
                    )}
                  </>
                )}
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MasterCertificates;