import { escapeEmptyDetail } from '../../utils/utils';
import DetailItem from '../DetailItem/DetailItem';

const DecodedPayloadObject = (props) => {

  const decodedPayload = props.payload;
  
  const getElements = () => {
    let elements = [];
    
    for (const [key, value] of Object.entries(decodedPayload)) {
    if(key !== null) 
      elements.push(
        <p key={key}><b>{escapeEmptyDetail(key)}:</b> {escapeEmptyDetail(value)}</p>
      )
    }

    return elements;
  }

  return (
    <DetailItem title={props.title} content={getElements()} />
  );
};

export default DecodedPayloadObject