import React from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  useUserState,
  useUserDispatch,
  tabActive
} from '../../context/UserContext';


const TabMenu = () => {
  const user = useUserState();
  const navigate = useNavigate();
  const userDispatch = useUserDispatch();
  var mainTab = localStorage.getItem('main_tab');

  const tabRouting = (e, value) => {
    tabActive(userDispatch, value);

    if (value === 0) {
      navigate('/master-certificates')
    } else if (value === 1) {
      navigate('/issued-certificates')
    } else if (value === 2) {
      navigate('/production-entities')
    }
  }

  return (
    <Tabs
      value={user.tabActive}
      variant='fullWidth'
      indicatorColor='primary'
      textColor='primary'
      aria-label='icon tabs example'
      onChange={tabRouting}
    >
      { mainTab === 'certificates' &&
        <Tab label='Master Certificates' />
      }
      { mainTab === 'certificates' &&
        <Tab label='Issued Certificates' />
      }
      { mainTab === 'certificates' &&
        <Tab label='Production Entities' />
      }
    </Tabs>
  );
};

export default TabMenu;
