import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from 'semantic-ui-react';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import { readableStepName } from '../../utils/utils';
import { escapeEmptyDetail } from '../../utils/utils';
import DetailItem from '../../components/DetailItem/DetailItem';

const ContextualizationEventModel = (event, decodedPayload) => {
  let startingValues = [];

  event.startingValues.forEach((a, index) => {
    let nameFormatted = a.name.replaceAll('_', ' ');
    startingValues.push(
      <Grid.Column mobile={16} tablet={8} computer={5} key={index}>
        <p className='detail-title'>{nameFormatted}</p>
        <p className='detail-text'>
          {a.value < 1 ? Math.round(a.value * 100) + '%' : a.value}
        </p>
      </Grid.Column>
    );
  });

  let supportingInformation = [];

  event.supportingInformation.forEach((a, index) => {
    let nameFormatted = a.name.replaceAll('_', ' ');
    supportingInformation.push(
      <Grid.Column mobile={16} tablet={8} computer={5} key={index}>
        <p className='detail-title'>{nameFormatted}</p>
        <p className='detail-text'>
          {a.value < 1 ? Math.round(a.value * 100) + '%' : a.value}
        </p>
      </Grid.Column>
    );
  });

  return (
    <>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
      }}>
        <p className='details-title'>
          <b>
            { readableStepName(event['@type']) }
          </b>
        </p>
        <DetailItem title='Title' content={escapeEmptyDetail(event.title)} />
        <DetailItem title='Description' content={escapeEmptyDetail(event.description)} />
        <DetailItem title='Party Id' content={escapeEmptyDetail(event.partyId)} copy={event.partyId} />
        <DetailItem title='Party Name' content={escapeEmptyDetail(event.partyName)} />
        <DetailItem title='Process Name' content={escapeEmptyDetail(event.processName)} />
        <DetailItem title='Process Version' content={escapeEmptyDetail(event.processVersion)} />
        <DetailItem title='Service Name' content={escapeEmptyDetail(event.serviceName)} />
        <DetailItem title='Issued Certificate' content={escapeEmptyDetail(event.issuedCertificate)} />
        <DetailItem title='Created at' content={event.createdAt.split('T', 1) + ' ' + event.createdAt.split('T', 2)[1].split('.', 1)+' (GMT)'} />
        <DetailItem
          title='Starting Values'
          content={event.startingValues?.length > 0 ?
            event.startingValues.map((el, index) => {
              return <p key={index}><b>{escapeEmptyDetail(el.name)}:</b> {escapeEmptyDetail(el.value)}</p>
            })
            :
            '-'
          }
        />
        <DetailItem
          title='Result'
          content={event.result?.length > 0 ?
            event.result.map((el, index) => {
              return <p key={index}><b>{escapeEmptyDetail(el.name)}:</b> {escapeEmptyDetail(el.value)}</p>
            })
            :
            '-'
          }
        />
        <DetailItem
          title='Supporting Information'
          content={event.supportingInformation?.length > 0 ?
            event.supportingInformation.map((el, index) => {
              return <p key={index}><b>{escapeEmptyDetail(el.name)}:</b> {escapeEmptyDetail(el.value)}</p>
            })
            :
            '-'
          }
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
      }}>
        { decodedPayload ?
          <DecodedPayload decodedPayload={decodedPayload} />
          :
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <p className='detail-title'>Payload </p>
              <p className='detail-text'>{escapeEmptyDetail(event.payload)}</p>
            </Grid.Column>
          </Grid>
        }
      </Box>
    </>
  );
};

export default ContextualizationEventModel;
