import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from 'semantic-ui-react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import ErrorMessage from '../../../components/Error/Error';
import Arrow from '../../../images/Arrow.svg';
import { useUserDispatch, tabActive } from '../../../context/UserContext';
import { listProductionEntity } from '../../../api/api';
import { escapeEmptyDetail } from '../../../utils/utils';
import DetailItem from '../../../components/DetailItem/DetailItem';
import AddProductionEntity from '../Add/AddProductionEntity.jsx';
import DeleteProductionEntity from '../Delete/DeleteProductionEntity.jsx';

const ProductionEntitiesDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userDispatch = useUserDispatch();

  const [loading, setLoading] = useState(true);
  const [productionEntity, setProductionEntity] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (window.location.pathname === `/production-entities/${params.id}`) {
      tabActive(userDispatch, 2)
    }
    window.localStorage.setItem('main_tab', 'certificates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let id = params.id;
    setLoading(true);

    if (id) {
      getProductionEntity(id);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getProductionEntity = (id) => {
    listProductionEntity(id)
    .then((res) => {
      setProductionEntity(res.data);
    })
    .catch((err) => {
      setHasError(true);
      setErrorStatus(err.response.status);
      setErrorDetail(err.response.data.detail);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <div className='payload'>
      {loading || (!productionEntity && !hasError) ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <>
             <section className='search-result'>
                <p
                  className='link'
                  onClick={() => {
                    navigate('/production-entities')
                  }}
                >
                  Production Entities
                </p>
                <p>
                  <img src={Arrow} alt='arrow'></img>
                </p>
                <p>{productionEntity.name}</p>
              </section>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  display: 'block',
                }}
              >
                <Grid container>
                  <Grid item md={10}>
                    <p className='details-title'>Production Entity Details: {productionEntity.name}</p>
                  </Grid>
                  <Grid item md={2}>
                    <section className='export-btn-wrapper'>
                      <Tooltip placement={'top'} arrow title='Edit Production Entity'>
                        <button className='sm-btn' onClick={(e) => {e.stopPropagation(); setEditModalOpen(true)}}> <EditIcon /> </button>
                      </Tooltip>
                      <Tooltip placement={'top'} arrow title='Delete Production Entity'>
                        <button className='sm-btn danger' onClick={(e) => {e.stopPropagation(); setDeleteModalOpen(true)}} > <DeleteIcon /> </button>
                      </Tooltip>
                    </section>
                  </Grid>
                </Grid>
                <Box
                sx={{
                  width: '100%',
                  display: 'grid',
                  justifyContent: 'initial'
                }}
                >
                  <DetailItem title='Id' content={escapeEmptyDetail(productionEntity.productionEntityId)} copy={productionEntity.productionEntityId} />
                  <DetailItem title='Certifying Body Party' content={escapeEmptyDetail(productionEntity.certifyingBodyPartyName)} />
                  <DetailItem title='Certificate Holder Party' content={escapeEmptyDetail(productionEntity.certificateHolderPartyName)} />
                  <DetailItem title='External Id' content={escapeEmptyDetail(productionEntity.externalId)} />
                  <DetailItem title='Production Entity Name' content={escapeEmptyDetail(productionEntity.name)} />
                  <DetailItem title='Production Entity Type' content={escapeEmptyDetail(productionEntity.productionEntityType)} />
                  <DetailItem title='Location' content={escapeEmptyDetail(productionEntity.location)} />
                  <DetailItem title='Lat Long' content={escapeEmptyDetail(productionEntity.latlong)} />
                  <DetailItem title='Volume Unit' content={escapeEmptyDetail(productionEntity.volumeUnit)} />
                  <DetailItem title='Volume' content={escapeEmptyDetail(productionEntity.volume)} />
                  <DetailItem
                    title='Supplemental Data'
                    content={productionEntity.supplementalData?.length > 0 ?
                      productionEntity.supplementalData.map((el, index) => {
                        return <p key={index}><b>{escapeEmptyDetail(el.name)}:</b> {escapeEmptyDetail(el.value)}</p>
                      })
                      :
                      '-'
                    }
                  />
                </Box>
              </Box>
              { editModalOpen &&
                <AddProductionEntity 
                  open={editModalOpen}
                  onClose={() => setEditModalOpen(false)}
                  onCloseSuccessfully={() => {setEditModalOpen(false); getProductionEntity(productionEntity.productionEntityId);}}
                  productionEntityId={productionEntity.productionEntityId}
                />
              }
              { deleteModalOpen &&
                <DeleteProductionEntity 
                  open={deleteModalOpen}
                  onClose={() => setDeleteModalOpen(false)}
                  onCloseSuccessfully={() => {setDeleteModalOpen(false); navigate('/production-entities');}}
                  productionEntityId={productionEntity.productionEntityId}
                />
              }
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ProductionEntitiesDetails

