import React, { useState, useRef, useEffect } from 'react';
import { Loader, Table, Modal, Button } from 'semantic-ui-react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import WarningOutlineIcon from '@mui/icons-material/WarningAmber';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportIcon from '@mui/icons-material/Report';
// import jwt_decode from 'jwt-decode';

import { useUserState } from '../../context/UserContext';
import Arrow from '../../images/Arrow.svg';
import ErrorMessage from '../../components/Error/Error';
import { getNextStep, listEvents, restatePayloadEvent } from '../../api/api';
import { useDidMountEffect } from '../../utils/utils';

// Event Models
import { DataCustodyRequestedEventModel } from '../../models/events/DataCustodyRequestedEvent';
import { DataCustodyReceivedEventModel } from '../../models/events/DataCustodyReceivedEvent';
import { ContextualizationRequestEventModel } from '../../models/events/ContextualizationRequestEvent';
import ContextualizationEventModel from '../../models/events/ContextualizationEvent';
import IDGeneratorEventModel from '../../models/events/IDGeneratorEvent';
import { RegistrationEventModel } from '../../models/events/RegistrationEvent';
import { AssetRegistrationEventModel } from '../../models/events/AssetRegistrationEvent';

// PDF file to export
import EventsToPDF from '../../pdfs/EventsSteps.jsx';

import NotableEventManualStep from './NotableEventManualStep.jsx';
import GeneralManualStep from './GeneralManualStep.jsx';
import ContextualizationManualStep from './ContextualizationManualStep';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import DetailItem from '../../components/DetailItem/DetailItem';
import { readableStepName, escapeEmptyDetail, escapeCommasForCsv } from '../../utils/utils';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '12px',
    top: '-6px',
  },
})(Tooltip)

const Payloads = () => {
  const navigate = useNavigate();
  const user = useUserState();
  const params = useParams();
  const search = useLocation().search;

  const ref = useRef([]);
  const hiddenRef = useRef();
  const pdfBtnRef = useRef([]);
  const jsonBtnRef = useRef([]);
  const csvBtnRef = useRef([]);

  const [eventSelected, setEventSelected] = useState();
  const [notableEventSelected, setNotableEventSelected] = useState();
  const [notableIconClicked, setNotableIconClicked] = useState(false);
  const [workflow, setWorkflow] = useState();
  const [payload, setPayload] = useState();
  const [events, setEvents] = useState([]);
  const [payload64, setPayload64] = useState();
  const [workflowStep, setWorkflowStep] = useState();
  const [decodedPayload, setDecodedPayload] = useState();
  const [restatementEvent, setRestatementEvent] = useState(false);
  const [notableEventModalOpen, setNotableEventModalOpen] = useState(false);
  const [generalManualStepModalOpen, setGeneralManualStepModalOpen] = useState(false);
  const [contextualizationManualStepModalOpen, setContextualizationManualStepModalOpen] = useState(false);
  const [restatementModalOpen, setRestatementModalOpen] = useState(false);
  const [payloadCompleted, setPayloadCompleted] = useState(false);
  const [payloadActive, setPayloadActive] = useState(false);
  const [payloadTransacted, setPayloadTransacted] = useState(false);
  const [payloadRetired, setPayloadRetired] = useState(false);
  const [notableErrorPosition, setNotableErrorPosition] = useState();
  const [currentStep, setCurrentStep] = useState();
  const [iconsPositions, setIconsPositions] = useState([]);
  const [activeEvents, setActiveEvents] = useState([]);
  const [activeNotableEvents, setActiveNotableEvents] = useState([]);
  const [relatedEventSelected, setRelatedEventSelected] = useState();
  const [eventsToExportCSV, setEventsToExportCSV] = useState();
  const [eventsToExportJSON, setEventsToExportJSON] = useState();
  const [eventsToPDFActive, setEventsToPDFActive] = useState();
  const [eventsToPDFInactive, setEventsToPDFInactive] = useState();
  const [loading, setLoading] = useState(true);
  const [relatedEventSelectedPosition, setRelatedEventSelectedPosition] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [errorDetail, setErrorDetail] = useState();
  const [hasError, setHasError] = useState(false);
  
  const getDepth = (o) => Object(o) === o ? 1 + Math.max(-1, ...Object.values(o).map(getDepth)) : 0

  useEffect(() => {
    window.scrollTo(0, 0);
    window.localStorage.setItem('main_tab', 'workflows');
    console.log('CONTEXT_API_UPDATED -> ', user)

    let payloadId = params.payloadId;
    setPayload(payloadId);

    listEvents(payloadId)
      .then((res) => {
        setEvents(res.data);
        switch (res.data.status) {
          case 'ACTIVE':
            setPayloadActive(true)
            break;
          case 'COMPLETED':
            setPayloadCompleted(true)
            break;
          case 'TRANSACTED':
            setPayloadTransacted(true)
            break;
          case 'RETIRED':
            setPayloadRetired(true)
            break;
          default:
        }

        if (res.data.events.length > 0) {
          res.data.events.forEach(el => {
            if(el.status ==='ACTIVE') {
              setPayload64(el.payload);
              try {
                const decodedPayloadAux = JSON.parse(window.atob(el.payload));
                
                const depth = getDepth(decodedPayloadAux);
                if(depth > 3) {
                  setDecodedPayload(null)
                } else {
                  setDecodedPayload(decodedPayloadAux); 
                }
              } catch (err) {
                setDecodedPayload(null);
              }
            }
          })
        }

        // checking for restataments
        if (res.data.restatementEvents.length) {
          setIconsPositions([]);
          setRestatementEvent(true);
        }

        // setting active events array state
        let activeArr = [];
        res.data.events?.forEach((s) => {
          if (s.status === 'ACTIVE') {
            activeArr.push(s);
          }
        })
        setActiveEvents(activeArr);

        // setting active notable events array state
        let activeNotableArr = [];
        res.data.notableEvents?.forEach((s) => {
          if (s.status === 'ACTIVE') {
            activeNotableArr.push(s);
          }
        })
        setActiveNotableEvents(activeNotableArr);

        if(res.data.status === 'ACTIVE') {
          getNextStep(payloadId)
            .then((res) => {
              if(res.data && res.data.order !== undefined)
                setCurrentStep(res.data.order);
              else 
                setCurrentStep(null);
            })  
        }

        setWorkflow(res.data.workflowDefinition);
        
      }).then(()=> {
        const format = new URLSearchParams(search).get('format');
        if (format !== '')
          downloadFileFromParam(format);
      })
      .catch((err) => {
        setHasError(true);
        setErrorStatus(err.response.data.status);
        setErrorDetail(err.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useDidMountEffect(() => {
    let firstStepAutoSelected = false;

    events?.events?.forEach((e, index) => {
      if (!firstStepAutoSelected && e.status === 'ACTIVE') {
        setEventSelected(e);
        firstStepAutoSelected = true;
      }
    })
  }, [events])

  // getting notable events positions
  useDidMountEffect(() => {
    let arr = [];
    let activeNotableEventsArr = activeNotableEvents;

    activeEvents?.forEach((event, index) => {
      activeNotableEventsArr?.forEach((notable, i) => {
        if (event.createdAt < notable.createdAt) {
          arr.push({ position: index + 1, event: notable });
        }
      })
    })

    if (activeEvents[0] === undefined) {
      activeNotableEventsArr?.forEach((notable, i) => {
        arr.push({ position: 0, event: notable });
      })
    }

    let uniqueObjects = [ ...new Map(arr.map((item) => [item.event.eventId, item])).values() ];
    
    let allEvents = events?.events?.concat( events.notableEvents, events.restatementEvents );
    allEvents?.forEach((a) => {
      if (a['@type']) {
        a.eventType = 'Event';
      } else if (a.isError !== undefined) {
        a.eventType = 'Notable Event';
      } else {
        a.eventType = 'Restatement Event';
      }
    });

    let eventsToPDFFormatted = allEvents?.sort( (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
    let eventsToPDFFormattedActive = [];
    let eventsToPDFFormattedInactive = [];

    eventsToPDFFormatted?.forEach((ev) => {
      if (ev.status === 'ACTIVE') {
        eventsToPDFFormattedActive.push(ev);
      } else {
        eventsToPDFFormattedInactive.push(ev);
      }
    })

    setEventsToExportJSON(eventsToPDFFormatted)
    setEventsToExportCSV(eventsToPDFFormatted)
    setEventsToPDFActive(eventsToPDFFormattedActive)
    setEventsToPDFInactive(eventsToPDFFormattedInactive)

    setEventsToExportJSON(eventsToPDFFormatted);
    setEventsToExportCSV(eventsToPDFFormatted);
    setEventsToPDFActive(eventsToPDFFormattedActive);
    setEventsToPDFInactive(eventsToPDFFormattedInactive);

    setIconsPositions(uniqueObjects);
  }, [activeEvents, activeNotableEvents, events])

  // getting notable event error position
  useDidMountEffect(() => {
    workflow?.steps.forEach((w, index) => {

      iconsPositions?.forEach((i) => {
        if (index === i.position && i.event.isError) {
          setNotableErrorPosition(index);
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconsPositions, workflow])

  // close events list if click outside
  useDidMountEffect(() => {
    const checkIfClickedOutside = (e) => {
      if ( notableIconClicked !== undefined && ref.current[0] && !ref.current[0]?.contains(e.target) ) {
        setNotableIconClicked(false);
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    }
  }, [notableIconClicked])

  const downloadFileFromParam = (format) => {
    setTimeout(() => {

      switch (format) {
        case 'pdf':
          pdfBtnRef.current.click();
          break;
        case 'json':
          jsonBtnRef.current.click();
          break;
        case 'csv':
          csvBtnRef.current.click();
          break;
        default:
      }

    }, 2000);
  }

  const cleanRelatedEventSelected = () => {
    setRelatedEventSelected();
    setRelatedEventSelectedPosition();
  }

  const selectEvent = (step) => {
    // if ( window.pageYOffset + window.innerHeight <= hiddenRef.current?.offsetTop ) {
      ref.current.scrollIntoView();
    // }

    setEventSelected(step);
    setNotableEventSelected();
    cleanRelatedEventSelected();
  }

  const selectNotableEvent = (notableEvent) => {
    // if ( window.pageYOffset + window.innerHeight <= hiddenRef.current?.offsetTop ) {
      ref.current.scrollIntoView();
    // }

    setNotableEventSelected(notableEvent);
    setNotableIconClicked(false);
    cleanRelatedEventSelected();
  }

  const getRelatedEvent = (relatedEventId) => {
    setRelatedEventSelected(relatedEventId);
    events?.events.forEach((r) => {
      if (relatedEventId === r.eventId) {
        setRelatedEventSelectedPosition(r.order);
      }
    });
  }

  const checkEventPosition = (index) => {
    let icon = '';
    let iconCount = 0;
    let eventsList = [];

    iconsPositions?.forEach((p) => {
      if (iconCount === 0 && p.position === index) {
        icon = (
          <span className='arrow-icon'>
            {p.event.isError ? (
              <CustomTooltip placement={'top'} arrow title='See Error details'>
                <WarningOutlineIcon
                  className='error'
                  onClick={() => {
                    selectNotableEvent(p.event)
                  }}
                ></WarningOutlineIcon>
              </CustomTooltip>
            ) : (
              <CustomTooltip
                placement={'top'}
                arrow
                title='See Notable Event(s) details'
              >
                <HelpOutlineIcon
                  className='warning'
                  onClick={() => {
                    selectNotableEvent(p.event)
                  }}
                ></HelpOutlineIcon>
              </CustomTooltip>
            )}
          </span>
        );
        eventsList.push(p);
        iconCount++;
      } else if (iconCount > 0 && p.position === index) {
        let headings = ['Party Name', 'Event Related', 'Created at'];
        icon = (
          <>
            <span className='arrow-icon'>
              {p.event.isError ? (
                <CustomTooltip
                  placement={'top'}
                  arrow
                  title='See Error details'
                >
                  <WarningOutlineIcon
                    className='error'
                    onClick={() => {
                      selectNotableEvent(p.event)
                    }}
                  ></WarningOutlineIcon>
                </CustomTooltip>
              ) : (
                ''
              )}

              <CustomTooltip
                placement={'top'}
                arrow
                title='See Notable Event(s) details'
              >
                <HelpOutlineIcon
                  className='warning'
                  onClick={() => {
                    notableListBox(p.position)
                  }}
                ></HelpOutlineIcon>
              </CustomTooltip>
            </span>
            <div
              className={`eventslist-wrapper ${
                notableIconClicked === p.position ? 'clicked' : ''
              }
                ${p.event.isError ? 'both-icons' : ''}
              `}
            >
              <div className={`eventslist`}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      {headings.map((h, index) => {
                        return (
                          <Table.HeaderCell key={index}>{h}</Table.HeaderCell>
                        )
                      })}
                      <CloseIcon className="close_pop" onClick={() => setNotableIconClicked(false)} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {iconsPositions?.map((h) => {
                      if (
                        h.position === p.position &&
                        h.event.isError === false
                      ) {
                        return (
                          <Table.Row
                            onClick={() => {
                              selectNotableEvent(h.event)
                            }}
                            className={h.error ? 'error' : ''}
                            key={h.partyId}
                          >
                            <Table.Cell>{h.event.partyName}</Table.Cell>
                            <Table.Cell>{h.event.relatedEventId}</Table.Cell>
                            <Table.Cell>
                              {h.event.createdAt.split('T', 1) +
                                ' ' +
                                h.event.createdAt
                                  .split('T', 2)[1]
                                  .split('.', 1) +
                                ' (GMT)'}
                            </Table.Cell>
                          </Table.Row>
                        )
                      }
                      // eslint-disable-next-line array-callback-return
                      return
                    })}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </>
        );
        eventsList.push(p);
      }
    })

    return icon;
  }

  const notableListBox = (e) => {
    if (notableIconClicked !== false)
      setNotableIconClicked(false);
    else 
      setNotableIconClicked(e)

  }

  const renderFlowchart = () => {
    let stepsQty = workflow?.steps?.length;
    return (
      <>
        <div className='workflow-wrapper'>
          {workflow?.steps.map((step, index) => (
            <div className='workflow-step' key={index}>
              <div
                className={` step-wrapper 
              ${(index + 1) % 5 === 0 && index !== 1 ? 'last' : ''} 
              ${
                (index + 2) % 5 === 0 && index !== 1 ? 'eventlist-to-left' : ''
              } 
              `}
              >
                <figure>
                  {index > 0 && (index + 1) % 6 !== 0 &&
                    <span
                    className={`${index < stepsQty ? 'arrow' : ''}`}
                  ></span>
                  }
                  {checkEventPosition(index)}
                </figure>
                <div
                  className={`step 
                  ${index < notableErrorPosition ? 'completed-error' : ''}
                  ${
                    index < currentStep - 1 || payloadCompleted
                      ? 'completed'
                      : ''
                  }
                  ${index === currentStep - 1 ? 'current' : ''}  
                  ${
                    (step.order === eventSelected?.order &&
                      !notableEventSelected) ||
                    relatedEventSelectedPosition === step.order
                      ? 'selected'
                      : ''
                  }
                  ${payloadTransacted ? 'transacted' : ''}
                  ${payloadRetired ? 'retired' : ''}
                  ${(index === currentStep - 1) &&
                    step.isManual
                    ? 
                    'manual' : ''}
                    ${(payloadActive && index >= currentStep - 1) ||
                      (index >= notableErrorPosition) ? 'next' : ''}  
                  `}
                  onClick={() => {
                    if ((index === currentStep - 1) &&
                        step.isManual &&
                        (step['@type'] === 'DataCustodyRequestedStep' ||
                        step['@type'] === 'DataCustodyReceivedStep' ||
                        step['@type'] === 'ContextualizationRequestStep' ||
                        step['@type'] === 'RegistrationStep' ||
                        step['@type'] === 'AssetRegistrationStep')
                      ){
                      setWorkflowStep(step);
                      setGeneralManualStepModalOpen(true);
                    } else if((index === currentStep - 1) &&
                        step.isManual &&
                        (step['@type'] === 'ContextualizationStep' ||
                        step['@type'] === 'IDGeneratorStep')
                      ){
                      setWorkflowStep(step);
                      setContextualizationManualStepModalOpen(true);
                    } else {
                      selectEvent(step)
                    }
                  }}
                >
                  <p>
                    { step.title ?
                      step.title
                    :
                      readableStepName(step['@type'])
                    }
                    {<span><br/>({step.isManual ? 'Manual' : 'Automated'})</span>}
                  </p>
                </div>
              </div>
              {(index + 1) % 5 === 0 && index !== 1 && index + 1 < stepsQty ? (
                <figure
                  className={`${
                    (index + 1) % 5 === 0 && index !== 1 && index + 1 < stepsQty
                      ? 'arrow-long-wrapper'
                      : ''
                  }`}
                >
                  <span
                    className={`${
                      (index + 1) % 5 === 0 && index !== 1 ? 'arrow-long' : ''
                    }`}
                  ></span>
                </figure>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </>
    );
  }

  const getEventObj = (step) => {
    let eventType = '';
    let eventObj = '';
    events.events?.forEach((a) => {
      if (step.order === a.order && a.status === 'ACTIVE') {
        eventType = `${a['@type']}`;
        eventObj = a;
      } else if (a.eventId === step) {
        eventType = `${a['@type']}`;
        eventObj = a;
      }
    })

    let decodedPayload = null;

    try {
      const decodedPayloadAux = JSON.parse(window.atob(eventObj.payload));
      const depth = getDepth(decodedPayloadAux);
      if(depth > 3) {
        decodedPayload = null;
      } else {
        decodedPayload = decodedPayloadAux;
      }
    } catch (err) {
      decodedPayload = null;
    }

    if(decodedPayload && decodedPayload.headers !== undefined) {
      decodedPayload = null
    }

    switch (eventType) {
      case 'DataCustodyRequestedEvent':
        return <>{DataCustodyRequestedEventModel(eventObj, decodedPayload)}</>;
      case 'DataCustodyReceivedEvent':
        return <>{DataCustodyReceivedEventModel(eventObj, decodedPayload)}</>;
      case 'ContextualizationRequestEvent':
        return <>{ContextualizationRequestEventModel(eventObj, decodedPayload)}</>;
      case 'ContextualizationEvent':
        return <>{ContextualizationEventModel(eventObj, decodedPayload)}</>;
      case 'IDGeneratorEvent':
        return <>{IDGeneratorEventModel(eventObj, decodedPayload)}</>;
      case 'RegistrationEvent':
        return <>{RegistrationEventModel(eventObj, decodedPayload)}</>;
      case 'AssetRegistrationEvent':
        return <>{AssetRegistrationEventModel(eventObj, decodedPayload)}</>;
      default:
        console.log('Step not set on the FE -> check Payloads.jsx file');
        break;
    };
  }

  // export workflow csv
  const EventCSV = (e) => {
    e.preventDefault();

    let headers = [ 'Type, Title, Description, Status, Event Id, New Custodian Party Name, New Custodian Party Id, Old Custodian Party Name, Old Custodian Party Id, Requesting Party Name, Receiving Party Id, Receiving Party Name, Receiving Party Id, Process Name, Process Version, Party Name, Party Id, Registry Name, Registry Id, Payload, Created at, Service Name, Issued Certificate, Starting Values, Result, Supporting Information, Notable Event Error?, Notable Event Message, Related Event Id', ];

    let eventsToCSVFormatted = eventsToExportCSV?.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))

    const formattingValues = (array) => {
      let b = array?.map((a) => {
        let c = array.reduce((obj) => {
          let value = ' ' + a.value;
          return { ...obj, [a['name']]: value};
        }, {});
        return c;
      });

      let str = '';
      b.forEach(el => {
        str = str + `${JSON.stringify(el)}; `
      })

      let strFormatted = str.replace(/{|}|'|'|"|\|`/g, '');
      return strFormatted;
    }

    let payloadsCsv = eventsToCSVFormatted.reduce((acc, row) => {
      const {
        title,
        description,
        status,
        eventId,
        newCustodianPartyName,
        newCustodianPartyId,
        oldCustodianPartyName,
        oldCustodianPartyId,
        requestingPartyName,
        requestingPartyId,
        receivingPartyName,
        receivingPartyId,
        processName,
        processVersion,
        partyName,
        partyId,
        registryName,
        registryId,
        payload,
        createdAt,
        serviceName,
        issuedCertificate,
        startingValues,
        result,
        supportingInformation,
        isError,
        content,
        relatedEventId,
      } = row;

      acc.push([
        row['@type']
          ? row['@type']
          : content !== undefined && !isError
          ? 'Notable Event'
          : content !== undefined && isError
          ? 'Notable Event Error'
          : 'Restatement Event',
        escapeCommasForCsv(title),
        escapeCommasForCsv(description),
        escapeCommasForCsv(status),
        escapeCommasForCsv(eventId),
        escapeCommasForCsv(newCustodianPartyName),
        escapeCommasForCsv(newCustodianPartyId),
        escapeCommasForCsv(oldCustodianPartyName),
        escapeCommasForCsv(oldCustodianPartyId),
        escapeCommasForCsv(requestingPartyName),
        escapeCommasForCsv(requestingPartyId),
        escapeCommasForCsv(receivingPartyName),
        escapeCommasForCsv(receivingPartyId),
        escapeCommasForCsv(processName),
        escapeCommasForCsv(processVersion),
        escapeCommasForCsv(partyName),
        escapeCommasForCsv(partyId),
        escapeCommasForCsv(registryName),
        escapeCommasForCsv(registryId),
        escapeCommasForCsv(payload),
        escapeCommasForCsv(createdAt.split('T', 1)[0] +
            ' ' +
          createdAt.split('T', 2)[1].split('.', 1) +
            ' (GMT)'),
        escapeCommasForCsv(serviceName),
        escapeCommasForCsv(issuedCertificate),
        startingValues ? JSON.stringify(formattingValues(startingValues)) : '',
        result ? JSON.stringify(formattingValues(result)) : '',
        supportingInformation ? JSON.stringify(formattingValues(supportingInformation)) : '',
        isError ? 'Yes' : '',
        escapeCommasForCsv(content),
        escapeCommasForCsv(relatedEventId),
      ]);
      return acc;
    }, [])

    downloadFile({
      data: [...headers, ...payloadsCsv].join('\n'),
      fileName: (payload).replaceAll('.','_') + '_events',
      fileType: 'text/csv',
    });
  }

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  // export events json
  const EventsToJson = (e) => {
    e.preventDefault();

    let eventsToJSONSorted = eventsToExportJSON?.sort(
      (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
    );

    downloadFile({
      data: JSON.stringify(eventsToJSONSorted),
      fileName: (payload).replaceAll('.','_') + '.json',
      fileType: 'text/json',
    });
  }

  const restatePayload = () => {
    setLoading(true);
    setRestatementModalOpen(false);
    restatePayloadEvent(payload)
    .then((res) => {
      navigate(0);
    })
    .catch((err) => {
      setHasError(true);
      setErrorStatus(err.response.status);
    })
  }

  return (
    <div className='details'>
      {loading ? (
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      ) : (
        <>
          {hasError ? (
            <ErrorMessage statusCode={errorStatus} detail={errorDetail}></ErrorMessage>
          ) : (
            <>
              <section className='search-result'>
                <p
                  className='link'
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  Workflows
                </p>
                <p>
                  <img src={Arrow} alt='arrow'></img>
                </p>
                <p
                  className='link'
                  onClick={() => {
                    navigate(`/workflows/${workflow.riid}`)
                  }}
                >
                  {workflow?.title}
                </p>
                <p>
                  <img src={Arrow} alt='arrow'></img>
                </p>
                <p>
                  {'Payload Id '}
                  {payload}
                </p>
              </section>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid lightgrey',
                  borderRadius: '4px',
                  padding: '20px',
                  marginBottom: '20px',
                  display: 'block',
                }}
              >
                <div className='top-content'>
                  <p className='details-title'>
                    <b>Payload Id {payload}</b>
                  </p>
                  <div className='subtitles'>
                    <div>
                      <p>Notable Event(s)</p>
                      <HelpOutlineIcon className='warning'></HelpOutlineIcon>
                    </div>
                    <div>
                      <p>Notable Event Error</p>
                      <WarningOutlineIcon className='error'></WarningOutlineIcon>
                    </div>
                  </div>
                </div>
                {iconsPositions ? (
                  <div className='flowchart'>{renderFlowchart()}</div>
                ) : (
                  ''
                )}
                <div className='bottom-content'>
                  {restatementEvent ? (
                    <>
                      <ReportIcon className='restate'></ReportIcon>
                      <p>Workflow Restated</p>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                {events.events ? (
                  <div className='btns-wrapper'>
                    <section className='action-btn-wrapper'>
                      { events.status === 'ACTIVE' &&
                        <Tooltip placement={'top'} arrow title='Add Notable Event'>
                          <button onClick={() => setNotableEventModalOpen(true)}>Add Notable Event</button>
                        </Tooltip>
                      }
                      { events.status !== 'TRANSACTED' && events.status !== 'RETIRED' &&
                        <Tooltip placement={'top'} arrow title='Restate Payload'>
                          <button onClick={() => setRestatementModalOpen(true)}>Restate Payload</button>
                        </Tooltip>
                      }
                    </section>
                    <span ref={ref}></span>
                    <section className='export-btn-wrapper export-btn-wrapper-halfrow'>
                      <PDFDownloadLink
                        document={EventsToPDF(
                          eventsToPDFActive,
                          eventsToPDFInactive,
                          payload
                        )}
                        fileName={(payload).replaceAll('.','_')}
                      >
                        <Tooltip placement={'top'} arrow title='Export PDF'>
                          <button ref={pdfBtnRef} className='sm-btn'> pdf </button>
                        </Tooltip>
                      </PDFDownloadLink>

                      <Tooltip placement={'top'} arrow title='Export JSON'>
                        <button
                          ref={jsonBtnRef}
                          className='sm-btn json'
                          onClick={EventsToJson}
                        >
                          {'{ }'}
                        </button>
                      </Tooltip>
                      <Tooltip placement={'top'} arrow title='Export CSV'>
                        <button ref={csvBtnRef} className='sm-btn' onClick={EventCSV}>
                          csv
                        </button>
                      </Tooltip>
                    </section>
                  </div>
                ) : (
                  ''
                )}
              </Box>

              {(eventSelected && !notableEventSelected) ||
              relatedEventSelected ? (
                <>
                  <span ref={hiddenRef}></span>
                    {getEventObj(
                    relatedEventSelected
                      ? relatedEventSelected
                      : eventSelected
                  )}
                </>
              ) : (
                ''
              )}


              {notableEventSelected && !relatedEventSelected ? (
                <>
                  <Box
                    sx={{
                      width: '100%',
                      border: '1px solid lightgrey',
                      borderRadius: '4px',
                      padding: '20px',
                      marginBottom: '20px',
                      display: 'block',
                    }}>
                      <p className='details-title'>
                        <b>
                          {notableEventSelected.isError ? (
                            <b>Notable Event Error</b>
                          ) : (
                            <b>Notable Event</b>
                          )}
                        </b>
                      </p>
                      <DetailItem title='Party Id' content={escapeEmptyDetail(notableEventSelected.partyId)} copy={notableEventSelected.partyId} />
                      <DetailItem title='Party Name' content={escapeEmptyDetail(notableEventSelected.partyName)} />
                      <DetailItem title='Payload Id' content={escapeEmptyDetail(notableEventSelected.payloadId)} copy={notableEventSelected.payloadId} />
                      <DetailItem title='Related Event' content={escapeEmptyDetail(notableEventSelected.relatedEventId)} execFunction={() => getRelatedEvent(notableEventSelected.relatedEventId)} />
                      <DetailItem title='Created at' content={notableEventSelected.createdAt.split('T', 1) + ' ' + notableEventSelected.createdAt.split('T', 2)[1].split('.', 1)+' (GMT)'} />
                      <DetailItem title='Reason' content={escapeEmptyDetail(notableEventSelected.content)} />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        border: '1px solid lightgrey',
                        borderRadius: '4px',
                        padding: '20px',
                        marginBottom: '20px',
                        display: 'block',
                      }}>

                      <DecodedPayload decodedPayload={decodedPayload} />
                    </Box>
                  </>
              ) : (
                ''
              )}
              { notableEventModalOpen &&
                <NotableEventManualStep 
                  open={notableEventModalOpen}
                  onClose={() => setNotableEventModalOpen(false)}
                  payloadId={payload}
                  payload64={payload64}
                  decodedPayload={decodedPayload}
                />
              }
              { generalManualStepModalOpen &&
                <GeneralManualStep 
                  open={generalManualStepModalOpen}
                  onClose={() => setGeneralManualStepModalOpen(false)}
                  payloadId={payload}
                  payload64={payload64}
                  decodedPayload={decodedPayload}
                  workflowStep={workflowStep}
                />
              }
              { contextualizationManualStepModalOpen &&
                <ContextualizationManualStep 
                  open={contextualizationManualStepModalOpen}
                  onClose={() => setContextualizationManualStepModalOpen(false)}
                  payloadId={payload}
                  payload64={payload64}
                  decodedPayload={decodedPayload}
                  workflowStep={workflowStep}
                />
              }
              { restatementModalOpen &&
                <Modal
                  open={restatementModalOpen}
                  onClose={() => setRestatementModalOpen(false)}
                  >
                  <Modal.Header>Restate Payload</Modal.Header>
                  <Modal.Content>
                    Are you sure you want to restate payload: '{payload}' ?
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={() => setRestatementModalOpen(false)}>
                      Cancel
                    </Button>
                    <Button negative onClick={restatePayload}>
                      Submit
                    </Button>
                  </Modal.Actions>
                </Modal>
              }
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Payloads
