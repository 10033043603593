import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Loader, Table, Header, Button } from 'semantic-ui-react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Pagination, Box, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material/';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { dateTimeFormatter } from '../../utils/utils';

const DecodedPayloadEditableTable = (props) => {

  const attrSchema = props.attributesJsonSchema;
  const ROWS_PER_PAGE = 5;
  const [totalPages, setTotalPages] = useState();
  const [activePage, setActivePage] = useState(1);
  const [activePageRows, setActivePageRows] = useState([]);
  const [rows, setRows] = useState(props.attributes);
  const [activeRow, setActiveRow] = useState(null);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [addingRow, setAddingRow] = useState(false);
  const [errors, setErrors] = useState();
  const IN_PAGE_INDEX = (activePage-1)*ROWS_PER_PAGE;

  useEffect(() => {

    setTotalPages(ROWS_PER_PAGE ? Math.ceil(rows.length / ROWS_PER_PAGE): 0);

    if (ROWS_PER_PAGE) {
      setActivePageRows(
        rows.slice(
          (activePage - 1) * ROWS_PER_PAGE,
          activePage * ROWS_PER_PAGE
        )
      );
    } else {
      setActivePageRows(rows);
    }

    validateAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, rows, ROWS_PER_PAGE]);

  const setEditableRow = (index) => {
    setActiveRow(rows[IN_PAGE_INDEX+index]);
    setActiveRowIndex(IN_PAGE_INDEX+index);
  }

  const removeRow = (index) => {
    const newRows = rows.filter((el, i) => i !== IN_PAGE_INDEX+index); 
    setRows(newRows);
  }

  const validateChanges = () => {
    let newErrors = null;

    if (activeRow) {
      attrSchema.properties.forEach( col => {
        if (col.required && (activeRow[col.field] === "" || activeRow[col.field] === null)) {
          newErrors = {...errors, [col.field]: `${col.headerName} is required`};
        }
      })
    }

    if(newErrors){
      props.setAttrError(true);
      setErrors(newErrors);
    } else {
      props.setAttrError(false);
      setErrors(null);
      saveChanges();
      validateAttributes();
    }
  }

  const validateAttributes = () => {
    let newErrors = null;

    attrSchema.requiredAttributes.forEach( att => {
      const found = rows?.find(el => el.name === att)
      if(!found) {
        newErrors = {...errors, missingAttributes: `${att} is required`};
      }
    })

    if(newErrors){
      props.setAttrError(true);
      setErrors(newErrors);
    } else {
      props.setAttrError(false);
      setErrors(null);
    }
  }

  const saveChanges = () => {
    
    setAddingRow(false);

    const newRows = rows.map((oldRow, i )=> {
      if (i === activeRowIndex) {
        return activeRow;
      }
      return oldRow;
    });

    setRows(newRows);
    props.onChangeAttributes(newRows);
    setActiveRow(null);
    setActiveRowIndex(null);
  }

  const cancelChanges = () => {

    if (addingRow) {
      removeRow(activeRowIndex-(activePage-1)*ROWS_PER_PAGE);
    }
    
    setActiveRow(null);
    setActiveRowIndex(null);
    setAddingRow(false);
  }

  const addRow = () => {
    
    setAddingRow(true);

    const newRow = {
      name: '',
      value: '',
      dataType: '',
      unitOfMeasure: '',
      refinementDate: dateTimeFormatter(new Date()),
      dataRefiner: ''
    }

    let newRows = rows.concat(newRow);

    setRows(newRows);

    setActiveRowIndex(newRows.length-1);
    setActiveRow(newRows[newRows.length-1]);
    setActivePage(ROWS_PER_PAGE ? Math.ceil(newRows.length / ROWS_PER_PAGE):0);
  }

  const handleNameChange = (value) => {

    const found = attrSchema?.defaults?.name?.find(el => el.name === value)
    if (found) {
      setActiveRow(
        {
          name: found.name ?? '',
          value: found.value ?? '',
          dataType: found.dataType ?? '',
          unitOfMeasure: found.unitOfMeasure ?? '',
          refinementDate: dateTimeFormatter(new Date()),
          dataRefiner: found.dataRefiner ?? ''
        }
      );
    } else {
      setActiveRow(
        {
          name: value,
          value: '',
          dataType: '',
          unitOfMeasure: '',
          refinementDate: dateTimeFormatter(new Date()),
          dataRefiner: ''
        }
      );
    }
  }

  // TODO: In body of empty table, provide a link to whatever needs to be done to make the table un-empty
  if (activePageRows.length === 0) {
    return (
      <div className='striped-table'>
        {!!props.title && <Header as='h2'>{props.title}</Header>}
        <div className='empty-table'>
          <p className='p2'>No results found...</p>
        </div>
      </div>
    );
  }

  if (props.loading) {
    return (
      <div className='striped-table'>
        {!!props.title && <Header as='h2'>{props.title}</Header>}
        <div className='empty-table'>
          <Loader active indeterminate size='small' />
        </div>
      </div>
    );
  }

  return (
    <div className='striped-table no-padding'>
      {!!props.title && <Header as='h2'>{props.title}</Header>}

      { errors?.missingAttributes &&
        <span className="error-label">{errors?.missingAttributes}</span>
      }
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            {attrSchema.properties.map((col, index) => (
              <Table.HeaderCell key={index} textAlign={'left'}>
                {col.headerName}
              </Table.HeaderCell>
            ))}
            {attrSchema.isEditable &&
              <Table.HeaderCell key={'actions'} textAlign={'left'}>
                Actions
              </Table.HeaderCell>
            }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {activePageRows.map((row, i) => (
            <Table.Row
              key={i}
              className={classNames({ clickable: props.rowsClickable })}
              onClick={row?.onClick}
            >
              {attrSchema.properties.map((col, index) => (
                <Table.Cell key={index} textAlign={'left'}>
                  {(addingRow || col.editable) && activeRowIndex === IN_PAGE_INDEX+i ?
                    <>
                      { col.field === "name" ?
                        <Autocomplete
                          freeSolo
                          options={attrSchema?.defaults?.name?.map((option) => option.name)}
                          onKeyDown={e => handleNameChange(e.target.value)}
                          onChange={(_event, newValue) => handleNameChange(newValue)}
                          value={activeRow[col.field] ?? ''}
                          renderInput={(params) => (
                            <TextField
                              required={col.required}
                              {...params}
                              label={col.headerName}
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                              error={Boolean(errors?.name)}
                              helperText={errors?.name}
                            />
                          )}
                        />
                      : col.field === "dataType" ?
                        <FormControl fullWidth={true}>
                          <InputLabel id='label-id'>{col.headerName}</InputLabel>
                          <Select
                            labelId='label-id'
                            label={col.headerName}
                            name={col.headerName}
                            value={activeRow[col.field] ?? ''}
                            onChange={(e) => setActiveRow({...activeRow, [`${col.field}`]: e.target.value})}
                            >
                              {attrSchema?.defaults?.dataType?.map(el =>
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      : col.type === "date" ?
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format='MM-DD-YYYY'
                            label={col.headerName}
                            value={dayjs(activeRow[col.field]) ? dayjs(activeRow[col.field]) : null}
                            onChange={(newValue) => setActiveRow({...activeRow, [`${col.field}`]: dayjs(newValue).format('YYYY-MM-DDT00:00:00Z')})}
                            slotProps={{
                              actionBar: { actions: ["clear"], position: "left" }
                            }}
                          />
                        </LocalizationProvider>
                      : col.type === "number" ?
                        <TextField 
                          fullWidth={true}
                          type="number"
                          label={col.headerName}
                          id={col.field}
                          name={col.field}
                          placeholder={col.headerName}
                          value={activeRow[col.field] ?? ''}
                          onChange={(e) => setActiveRow({...activeRow, [`${col.field}`]: e.target.value})}
                        />
                      :
                        <TextField 
                          fullWidth={true}
                          label={col.headerName}
                          id={col.field}
                          name={col.field}
                          placeholder={col.headerName}
                          value={activeRow[col.field] ?? ''}
                          onChange={(e) => setActiveRow({...activeRow, [`${col.field}`]: e.target.value})}
                        />
                      }
                    </>
                    :
                    <>
                      {activeRowIndex === IN_PAGE_INDEX+i ?
                        <span>{activeRow[col.field]}</span>
                      : col.field === "refinementDate" ?
                        <span>{dateTimeFormatter(row[col.field])}</span>
                      :
                        <span>{row[col.field]}</span>
                      }
                    </>
                  }
                </Table.Cell>
              ))}
              {attrSchema.isEditable &&
                <Table.Cell key={`actions-${i}`} textAlign={'left'}>
                  {activeRowIndex === IN_PAGE_INDEX+i ?
                    <div className="table_cell_action">
                      <Button onClick={() => cancelChanges()}>cancel</Button>
                      <Button className='actionButton' type='submit' onClick={() => validateChanges()}>save</Button>
                    </div>
                  :
                    <div className="table_cell_action">
                      <EditIcon onClick={() => setEditableRow(i)}/>
                      <DeleteIcon onClick={() => removeRow(i)}/>
                    </div>
                  }
                </Table.Cell>
              }
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Grid container spacing={2}>
        <Grid item md={4}>
          { attrSchema.isAddable &&
            <Button onClick={() => addRow()}>add attribute</Button>
          }
        </Grid>
        <Grid item md={4}>
          <Box
            component='div'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '3rem 0'
            }}
            >
            <Pagination
              count={totalPages}
              page={activePage}
              onChange={(event, value) => setActivePage(value)}
              defaultPage={0}
              color='primary'
              size='small'
            />
          </Box>
        </Grid>
        <Grid item md={4}>
        </Grid>
      </Grid>
    </div>
  );
};

export default DecodedPayloadEditableTable;
