/* eslint-disable no-unused-vars */
import React from 'react';
import Logo from '../images/fiutur_logo.png';
import Kanit from '../fonts/Kanit-Regular.ttf';

import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import { readableStepName, escapeEmptyDetail } from '../utils/utils';

const WorkflowPDF = (workflow) => {

  Font.register({
    family: 'Kanit',
    src: Kanit
  })

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#03241C',
      fontFamily: 'Kanit'
    },
    logo: {
      width: '100px',
      height: 'auto',
      margin: 20,
    },
    section: {
      margin: 20,
      marginBottom: 10,
      padding: 10,
      borderRadius: 6,
      fontSize: '10px',
      fontWeight: 'normal',
      backgroundColor: '#FFFFFF',
    },
    content: {
      flexDirection: 'row',
    },
    col: {
      marginBottom: 10,
      marginRight: 20,
    },
    eventTitle: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    eventTextTitle: {
      fontSize: '12px',
      color: '#03241C',
      marginTop: 10,
    },
    eventText: {
      marginTop: 5,
      fontWeight: 'light',
    },
    eventTextAmt: {
      marginTop: 5,
    },
    pdfTitle: {
      margin: 20,
      marginBottom: 10,
      color: '#FFFFFF',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  });

  const WorkflowStepsToPDF = () => (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image style={styles.logo} src={Logo} alt='logo' />
        <View style={styles.pdfTitle}>
          <Text style={styles.eventText}>Reference Implementation Id</Text>
          <Text style={styles.eventText}>{workflow?.riid}</Text>
        </View>
        {
          // eslint-disable-next-line array-callback-return
          workflow?.steps.map((s, index) => {
            switch (s['@type']) {
              case 'DataCustodyReceivedStep':
                return (
                  <View style={styles.col} key={index}>
                    <View style={styles.section}>
                      <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                      </Text>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextAmt}>
                            Number of active payloads: {s.payloadsAmount}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextTitle}>
                            Title:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.title)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Description:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.description)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Old Custodian Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.oldCustodianPartyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            New Custodian Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.newCustodianPartyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Old Custodian Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.oldCustodianPartyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            New Custodian Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.newCustodianPartyName)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              case 'ContextualizationStep':
                return (
                  <View style={styles.col} key={index}>
                    <View style={styles.section}>
                      <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                      </Text>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextAmt}>
                            Number of active payloads: {s.payloadsAmount}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextTitle}>
                            Title:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.title)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Description:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.description)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.partyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Process Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.processName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.partyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Process Version:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.processVersion)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              case 'ContextualizationRequestStep':
                return (
                  <View style={styles.col} key={index}>
                    <View style={styles.section}>
                      <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                      </Text>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextAmt}>
                            Number of active payloads: {s.payloadsAmount}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextTitle}>
                            Title:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.title)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Description:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.description)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Requesting Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.requestingPartyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Receiving Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.receivingPartyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Process Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.processName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Requesting Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.requestingPartyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Receiving Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.receivingPartyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Process Version:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.processVersion)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              case 'IDGeneratorStep':
                return (
                  <View style={styles.col} key={index}>
                    <View style={styles.section}>
                      <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                      </Text>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextAmt}>
                            Number of active payloads: {s.payloadsAmount}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextTitle}>
                            Title:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.title)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Description:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.description)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.partyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Process Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.processName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.partyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Process Version:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.processVersion)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              case 'DataCustodyRequestedStep':
                return (
                  <View style={styles.col} key={index}>
                    <View style={styles.section}>
                      <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                      </Text>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextAmt}>
                            Number of active payloads: {s.payloadsAmount}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextTitle}>
                            Title:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.title)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Description:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.description)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Old Custodian Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.oldCustodianPartyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            New Custodian Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.newCustodianPartyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Old Custodian Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.oldCustodianPartyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            New Custodian Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.newCustodianPartyName)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              case 'RegistrationStep':
                return (
                  <View style={styles.col} key={index}>
                    <View style={styles.section}>
                      <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                      </Text>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextAmt}>
                            Number of active payloads: {s.payloadsAmount}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextTitle}>
                            Title:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.title)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Description:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.description)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Old Custodian Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.oldCustodianPartyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Old Custodian Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.oldCustodianPartyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Registry Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.registryId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Registry Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.registryName)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              case 'AssetRegistrationStep':
                return (
                  <View style={styles.col} key={index}>
                    <View style={styles.section}>
                      <Text style={styles.eventTitle}>
                          { readableStepName(s['@type']) }
                      </Text>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextAmt}>
                            Number of active payloads: {s.payloadsAmount}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.content}>
                        <View style={styles.col}>
                          <Text style={styles.eventTextTitle}>
                            Title:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.title)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Description:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.description)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Old Custodian Party Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.oldCustodianPartyId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Old Custodian Party Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.oldCustodianPartyName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Registry Account Id:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.registryAccountId)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Registry Account Name:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.registryAccountName)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Registry:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.registry)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Registry Provider:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.registryProvider)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Registry Environment:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.registryEnvironment)}
                          </Text>
                          <Text style={styles.eventTextTitle}>
                            Registry API Version:
                          </Text>
                          <Text style={styles.eventText}>
                            {escapeEmptyDetail(s.registryAPIVersion)}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                );
              default:
                console.log(
                  'Step not set on the FE -> check file WorkflowSteps.jsx'
                );
            }
          })
        }
      </Page>
    </Document>
  );

  return <>{WorkflowStepsToPDF()}</>;
};

export default WorkflowPDF;