import { Grid } from 'semantic-ui-react';
import Box from '@mui/material/Box';
import DecodedPayload from '../../components/DecodedPayload/DecodedPayload';
import { readableStepName, escapeEmptyDetail } from '../../utils/utils';
import DetailItem from '../../components/DetailItem/DetailItem';

export const ContextualizationRequestEventModel = (event, decodedPayload) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
      }}>
        <p className='details-title'>
          <b>
            {
              readableStepName(event['@type'])
            }
          </b>
        </p>
        <DetailItem title='Title' content={escapeEmptyDetail(event.title)} />
        <DetailItem title='Description' content={escapeEmptyDetail(event.description)} />
        <DetailItem title='Receiving Party Id' content={escapeEmptyDetail(event.receivingPartyId)} copy={event.receivingPartyId} />
        <DetailItem title='Receiving Party Name' content={escapeEmptyDetail(event.receivingPartyName)} />
        <DetailItem title='Requesting Party Id' content={escapeEmptyDetail(event.requestingPartyId)} copy={event.requestingPartyId} />
        <DetailItem title='Requesting Party Name' content={escapeEmptyDetail(event.requestingPartyName)} />
        <DetailItem title='Process Name' content={escapeEmptyDetail(event.processName)} />
        <DetailItem title='Process Version' content={escapeEmptyDetail(event.processVersion)} />
        <DetailItem title='Created at' content={event.createdAt.split('T', 1) + ' ' + event.createdAt.split('T', 2)[1].split('.', 1)+' (GMT)'} />
      </Box>
      <Box
        sx={{
          width: '100%',
          border: '1px solid lightgrey',
          borderRadius: '4px',
          padding: '20px',
          marginBottom: '20px',
          display: 'block',
      }}>
        { decodedPayload ?
          <DecodedPayload decodedPayload={decodedPayload} />
          :
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <p className='detail-title'>Payload </p>
              <p className='detail-text'>{escapeEmptyDetail(event.payload)}</p>
            </Grid.Column>
          </Grid>
        }
      </Box>
    </>
  );
};
